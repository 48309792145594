import React, { useCallback, useEffect } from 'react';
import cs from 'classnames';
import { grey_900 } from '@fiverr-private/sass/helpers/index';
import { EyeIcon } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { Providers, useMonitoring } from '../../utils/monitoring';
import { PlusButton } from './PlusButton';

import styles from './AddEmptyBlock.module.scss';

export interface EventsConfig {
    type?: string;
    clickType?: string;
    [key: string]: any;
}

interface AddEmptyBlockProps extends React.PropsWithChildren<any> {
    className?: string;
    wrapperClassName?: string;
    contentWrapperClassName?: string;
    descriptionClassName?: string;
    leftContent: React.ReactNode;
    descriptionTitle?: React.ReactNode;
    description: React.ReactNode;
    buttonText: React.ReactNode;
    buttonHref?: string;
    mpEvents?: EventsConfig;
    bqEvents?: Partial<EventsConfig>;
    onClick?: () => void;
    isPrivate?: boolean;
}

const T_KEY = 'seller_pages_toolbox.add_empty_block';

export const AddEmptyBlock: React.FC<AddEmptyBlockProps> = ({
    className,
    wrapperClassName,
    contentWrapperClassName,
    descriptionClassName,
    leftContent,
    descriptionTitle,
    description,
    buttonText,
    buttonHref,
    mpEvents,
    bqEvents,
    onClick,
    isPrivate,
}) => {
    const { fireEvents } = useMonitoring();

    const onAddClick = useCallback(() => {
        if (mpEvents?.clickType) {
            fireEvents({
                [Providers.Mixpanel]: {
                    type: mpEvents.clickType,
                },
            });
        }

        if (bqEvents?.clickType) {
            fireEvents({
                [Providers.BigQuery]: {
                    type: bqEvents.clickType,
                    ...bqEvents,
                },
            });
        }
        if (onClick) {
            onClick();
        }
    }, [mpEvents, fireEvents, bqEvents, onClick]);

    useEffect(() => {
        if (mpEvents?.type) {
            fireEvents({
                [Providers.Mixpanel]: {
                    type: mpEvents?.type,
                },
            });
        }
    }, [mpEvents, fireEvents]);

    return (
        <div className={cs(styles.container, className, 'flex')}>
            {isPrivate && (
                <div className={styles.private}>
                    <div className="m-r-4 flex">
                        <EyeIcon color={grey_900} />
                    </div>
                    <I18n k={`${T_KEY}.private`} />
                </div>
            )}
            <div className={cs(styles.wrapper, wrapperClassName, 'flex flex-center')}>
                <div className={cs(contentWrapperClassName, 'flex flex-row p-24')}>
                    {leftContent}
                    <div className={cs('flex flex-col flex-center', { 'm-l-32': !!leftContent })}>
                        {descriptionTitle && (
                            <div className={cs(styles.descriptionTitle, 'co-text-darker text-bold m-b-8')}>
                                {descriptionTitle}
                            </div>
                        )}
                        <div className={cs(descriptionClassName, 'co-text-darker m-b-12')}>{description}</div>
                        <PlusButton className={styles.button} href={buttonHref} onClick={onAddClick}>
                            {buttonText}
                        </PlusButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
