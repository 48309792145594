import { merge, snakeCase } from 'lodash';
import { bigQuery as bq } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { serialize } from '@fiverr-private/seller_pages_toolbox';
import { DefaultData } from './types';

interface BQPageElement {
    type?: string;
    text?: string;
    name?: string;
}

export interface BQPageViewOptions {
    type: string;
    element?: BQPageElement;
    pageName?: string;
    [key: string]: any;
}

export interface BQClickOptions {
    type: string;
    pageName?: string;
    element?: BQPageElement;
    memberId?: number;
    [key: string]: any;
    organization?: {
        id?: string;
    };
}

export interface BQEnrichment {
    type?: string;
    group?: string;
    feature?: {
        name: string;
    };
    seller?: {
        id?: string;
    };
    entity: string;
    action?: {
        type: string;
    };
    user?: {
        id: number;
    };
    page?: {
        ctx_id?: string;
        name?: string;
        element?: BQPageElement;
        component?: any;
    };
    organization?: {
        id?: string;
    };
}

export const bigQuery = {
    async pageView({ type, element, ...rest }: BQPageViewOptions, defaultData: DefaultData = {}) {
        const { pageCtxId, userId, organizationId } = getContext();

        const freelancerId = defaultData?.seller?.user?.id;

        /** @type {Partial<import('./index').BQEnrichment>} */
        const payload = {
            entity: 'client',
            user: {
                id: userId,
            },
            feature: {
                name: 'fiverr_business',
            },
            seller: {
                id: freelancerId,
            },
            type: snakeCase(type),
            group: 'user_impressions',
            page: {
                element,
                name: defaultData.pageName,
                ctx_id: pageCtxId,
            },
            organization: {
                id: organizationId,
            },
        };

        const data = merge({}, payload, serialize(rest));

        bq.send(data);
    },

    async click({ type, element, component, ...rest }: BQClickOptions, defaultData: DefaultData = {}) {
        const { pageCtxId, userId, organizationId } = getContext();

        const sellerId = defaultData?.seller?.user?.id;

        const serializedType = type
            .split('.')
            .map((subType) => snakeCase(subType))
            .join('.');

        /** @type {Partial<import('./index').BQEnrichment>} */
        const payload = {
            entity: 'client',
            user: {
                id: userId,
            },
            feature: {
                name: 'fiverr_business',
            },
            type: serializedType,
            group: 'user_action',
            seller: {
                id: sellerId,
            },
            action: {
                type: 'click',
            },
            page: {
                element,
                component,
                name: defaultData.pageName,
                ctx_id: pageCtxId,
            },
            organization: {
                id: organizationId,
            },
        };

        const data = merge({}, payload, serialize(rest));

        bq.send(data);
    },
};
