import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { RespondTimeInfoProps } from './types';

const T_PREFIX = 'agency_page_perseus.contact';

const getRespondTimeText = (hours: number) =>
    hours === 1 ? `${T_PREFIX}.responds_in_hour` : `${T_PREFIX}.responds_in_hours`;

export const RespondTimeInfo = ({ className }: RespondTimeInfoProps) => {
    const { responseTime } = useAgencySellerData(selectSellerSlice);
    const inHours = responseTime?.inHours;

    if (!inHours) {
        return null;
    }

    return (
        <div className={classnames(className, 'flex flex-center responds-info')}>
            <span>
                <I18n k={getRespondTimeText(inHours)} params={{ hour: inHours }} />
            </span>
        </div>
    );
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        responseTime: seller.responseTime,
    };
}
