import { merge } from 'lodash';
import { mixpanel as mp } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

const PAGE_NAME = 'agency page';

const commonData = () => {
    const { userId, organizationId } = getContext();

    return {
        'user id': userId?.toString(),
        'is guest': !userId,
        'organization id': organizationId,
        'seller id': userId?.toString(),
        'page name': PAGE_NAME,
    };
};

export const mixpanel = {
    event: (type, payload = {}) => {
        const data = merge({}, commonData(), payload);

        mp.track(type, data);
    },
};
