import { merge } from 'lodash';
import { bigQuery as bq } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

export const PAGE_NAME = 'agency page';

export const enum EVENT_TYPE {
    CLICKED_ON_AGENCY_BOOK_MEETING = 'clicked_on_agency_book_a_meeting_button',
    ABOUT_OUR_AGENCY_IS_SHOWN = 'about_our_agency_is_shown',
    AGENCY_OUR_TEAM_IS_SHOWN = 'agency_our_team_is_shown',
    AGENCY_EMPLOYEE_IS_SHOWN = 'agency_employee_is_shown',
    CLICKED_ON_EMPLOYEE_CARD_IN_OUR_TEAM = 'clicked_on_employee_card_in_our_team',
    AGENCY_EXPERT_INDUSTRIES_IS_SHOWN = 'agency_expert_industries_is_shown',
    CLICKED_ON_SHOW_MORE_IN_AGENCY_INDUSTRIES = 'clicked_on_show_more_in_agency_industries',
    AGENCY_AMONG_OUR_CLIENTS_IS_SHOWN = 'agency_among_our_clients_is_shown',
    HOVER_ON_AGENCY_AMONG_OUR_CLIENTS = 'hover_on_agency_among_our_clients',
    AGENCY_SERVICES_IS_SHOWN = 'agency_services_is_shown',
    CLICKED_ON_GET_QUOTE = 'seller_page.get_quote',
    CLICKED_ON_NAVIGATION_IN_SERVICES = 'clicked_on_navigation_in_agency_provided_services',
    AGENCY_SPECIFIC_SERVICE_IS_SHOWN = 'agency_specific_service_is_shown',
    CLICKED_ON_SEE_MORE_IN_SERVICES = 'clicked_on_see_more_in_agency_provided_services',
    AGENCY_SERVICE_POPUP_IS_SHOWN = 'agency_service_popup_is_shown',
    AGENCY_POPULAR_PACKAGES_IS_SHOWN = 'agency_popular_packages_is_shown',
    SELLER_PORTFOLIO_COMPONENT_IS_SHOWN = 'seller_portfolio_component_is_shown',
    CLICKED_ON_AGENCY_MEETING_DURATION_BUTTON = 'clicked_on_agency_meeting_duration_button',
    CLICKED_ON_AGENCY_MEETING_DATE_BUTTON = 'clicked_on_agency_meeting_date_button',
    CLICKED_ON_AGENCY_MEETING_HOUR_BUTTON = 'clicked_on_agency_meeting_hour_button',
    CLICKED_ON_AGENCY_MEETING_BOOK_MEETING = 'clicked_on_agency_meeting_book_meeting',
    CLICKED_ON_NAVIGATION_IN_AGENCY_PROVIDED_SERVICES_POPUP = 'clicked_on_navigation_in_agency_provided_services_popup',
    SELLER_PAGE_CLICKED_ON_SHOW_MORE_SERVICE = 'seller_page_clicked_on_show_more_service',
    BOOK_MEETING_BUTTON_IS_SHOWN = 'book_a_meeting_is_shown',
    CLICKED_ON_AGENCY_REVIEWS = 'clicked_on_agency_reviews',
    CLICKED_ON_AGENCY_TABS = 'clicked_on_agency_tabs',
    CLICKED_ON_READ_MORE_IN_ABOUT_OUR_AGENCY = 'clicked_on_read_more_in_about_our_agency',
    CLICKED_ON_SEE_ALL_IN_OUR_TEAM = 'clicked_on_see_all_in_our_team',
    CLICKED_ON_NAVIGATION_IN_OUR_TEAM = 'clicked_on_navigation_in_our_team',
    CLICKED_ON_CONTACT_US_IN_DROPDOWN = 'seller_page.contact_me', // existing event used for tracking clicks on "Contact us" button
    CLICKED_ON_CONTACT_US_DROPDOWN = 'clicked_on_contact_us_dropdown', // new
    CLICKED_ON_CREATE_BRIEF_BUTTON = 'clicked_on_create_brief_button',
    CLICKED_ON_CREATE_BRIEF_IN_CONTACT_DROPDOWN = 'clicked_on_create_brief_in_contact_dropdown',
    CREATE_BRIEF_BUTTON_SHOWN = 'professional_brief_button_shown',
}

const commonData = () => {
    const { pageCtxId, userId, organizationId, isTouch } = getContext();

    return {
        entity: 'client',
        user: {
            id: userId,
        },
        domain: {
            name: 'agencies',
        },
        seller: {
            id: baseData?.seller?.id,
        },
        page: {
            name: PAGE_NAME,
            ctx_id: pageCtxId,
        },
        organization: {
            id: organizationId,
        },
        platform: isTouch ? 'mobile_web' : 'web',
    };
};
let baseData = {
    seller: {
        id: null,
    },
};

export const bigQuery = {
    // TODO refactor, make bigQuery as context layer
    initBaseData: (data) => (baseData = data),
    clickEvent: (type: EVENT_TYPE, payload = {}) => {
        const base = {
            ...commonData(),
            type,
            group: 'user_actions',
            action: {
                type: 'click',
            },
        };
        const additionalData = eventPageData(type) || {};

        bq.send(merge({}, base, additionalData, payload));
    },

    hoverEvent: (type: EVENT_TYPE, payload = {}) => {
        const base = {
            ...commonData(),
            type,
            group: 'user_actions',
            action: {
                type: 'hover',
            },
            page: {
                element: {
                    type: 'hover',
                },
            },
        };
        const additionalData = eventPageData(type) || {};

        bq.send(merge({}, base, additionalData, payload));
    },

    impressionEvent: (type: EVENT_TYPE, payload = {}) => {
        const base = {
            ...commonData(),
            type,
            group: 'user_impressions',
            action: {
                type: 'imp',
            },
        };
        const additionalData = eventPageData(type) || {};

        bq.send(merge({}, base, additionalData, payload));
    },
};

const eventPageData = (type: EVENT_TYPE) => {
    switch (type) {
        case EVENT_TYPE.CLICKED_ON_EMPLOYEE_CARD_IN_OUR_TEAM:
        case EVENT_TYPE.CLICKED_ON_NAVIGATION_IN_SERVICES:
        case EVENT_TYPE.CLICKED_ON_GET_QUOTE:
        case EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_DURATION_BUTTON:
        case EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_DATE_BUTTON:
        case EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_HOUR_BUTTON:
        case EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_BOOK_MEETING:
        case EVENT_TYPE.CLICKED_ON_NAVIGATION_IN_AGENCY_PROVIDED_SERVICES_POPUP:
        case EVENT_TYPE.SELLER_PAGE_CLICKED_ON_SHOW_MORE_SERVICE:
        case EVENT_TYPE.CLICKED_ON_READ_MORE_IN_ABOUT_OUR_AGENCY:
        case EVENT_TYPE.CLICKED_ON_NAVIGATION_IN_OUR_TEAM:
        case EVENT_TYPE.CLICKED_ON_AGENCY_BOOK_MEETING:
        case EVENT_TYPE.CLICKED_ON_CONTACT_US_DROPDOWN:
        case EVENT_TYPE.CLICKED_ON_CONTACT_US_IN_DROPDOWN:
        case EVENT_TYPE.CLICKED_ON_CREATE_BRIEF_BUTTON:
        case EVENT_TYPE.CLICKED_ON_CREATE_BRIEF_IN_CONTACT_DROPDOWN: {
            return {
                page: {
                    element: {
                        type: 'button',
                    },
                },
            };
        }
        case EVENT_TYPE.CLICKED_ON_SHOW_MORE_IN_AGENCY_INDUSTRIES:
        case EVENT_TYPE.CLICKED_ON_SEE_ALL_IN_OUR_TEAM: {
            return {
                page: {
                    element: {
                        type: 'link',
                    },
                },
            };
        }
        case EVENT_TYPE.CLICKED_ON_AGENCY_TABS: {
            return {
                page: {
                    element: {
                        type: 'tab',
                    },
                    component: {
                        name: 'tab',
                    },
                },
            };
        }
        case EVENT_TYPE.CREATE_BRIEF_BUTTON_SHOWN: {
            return {
                sub_group: 'fiverr_pro',
                page: {
                    element: {
                        name: 'button',
                    },
                },
                feature: {
                    name: 'fiverr_pro',
                },
            };
        }
        default: {
            return {};
        }
    }
};
