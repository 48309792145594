import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { CheckStarSolidIcon } from '@fiverr-private/icons';
import { white } from '@fiverr-private/sass/helpers';
import { Box } from '@fiverr-private/theme';

import {
    avatarImageClass,
    avatarImageMinimizedClass,
    proIconWrapperClass,
    proIconWrapperMinimizedClass,
} from './styles.ve.css';

interface AgencyAvatarProps {
    avatarUrl: string;
    isPro?: boolean;
    isMinimized?: boolean;
}

export const AgencyAvatar = ({ avatarUrl, isPro = false, isMinimized = false }: AgencyAvatarProps) => (
    <Box position="relative">
        <img src={avatarUrl} alt="Avatar" className={isMinimized ? avatarImageMinimizedClass : avatarImageClass} />
        {isPro && (
            <Box className={isMinimized ? proIconWrapperMinimizedClass : proIconWrapperClass}>
                <Icon size={isMinimized ? 16 : 24}>
                    <CheckStarSolidIcon color={white} />
                </Icon>
            </Box>
        )}
    </Box>
);
