import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Center, Container } from '@fiverr-private/layout_components';
import { MonitoringSources } from '@fiverr-private/seller_pages_toolbox';
import { RespondTimeInfo } from '../RespondTimeInfo';
import { useIsStickyLayoutVisible } from '../../hooks/useIsStickyLayoutVisible';

import {
    CONTACT_BUTTON_POSITIONS,
    contactButtonBiEnrichment,
} from '../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { AgencyContactButton } from '../AgencyContactButton';
import { SIZES } from '../../constants';
import { useShowContactSellerModalUpdater } from '../../hooks/useShowContactSellerModal';
import { containerClass, contactButtonClass } from './styles.ve.css';

export interface StickyFooterProps {
    observeHideRef?: React.RefObject<HTMLElement>;
}

export const StickyFooter = ({ observeHideRef }: StickyFooterProps) => {
    const isVisible = useIsStickyLayoutVisible(observeHideRef);

    const setShowContactSellerModal = useShowContactSellerModalUpdater();

    const handleContactOpen = () => setShowContactSellerModal(true);

    const contactButtonProps = {
        title: translate('agency_page_perseus.contact_us'),
        onContactOpen: handleContactOpen,
        source: MonitoringSources.stickyHeader,
        buttonClassName: contactButtonClass,
        biEnrichment: contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.STICKY }),
    };

    const agencyContactButtonProps = {
        source: CONTACT_BUTTON_POSITIONS.STICKY_FOOTER,
        biEnrichment: contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.STICKY_FOOTER }),
        className: contactButtonClass,
        size: SIZES.LG,
    };

    return (
        <Center hidden={!isVisible} className={containerClass} padding="8" borderTopWidth="sm" borderColor="grey_400">
            <AgencyContactButton {...agencyContactButtonProps} contactButtonProps={contactButtonProps} />
            <Container marginTop="3">
                <RespondTimeInfo />
            </Container>
        </Center>
    );
};
