import React, { useMemo } from 'react';
import { useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { fireClickEvents } from './fire-click-events';
import { firePageViewEvents } from './fire-page-view-events';
import { Providers } from './monitoring-constants';
import { BQPageViewOptions } from './big-query';
import { MPPageViewOptions } from './mixpanel';
import { GrafanaOptions } from './grafana';

interface PageViewEventsMap {
    bigQuery?: BQPageViewOptions;
    mixpanel?: MPPageViewOptions;
    grafana?: GrafanaOptions;
}

export const useMonitoring = (eventsMap?: PageViewEventsMap) => {
    const seller = useAgencySellerData(selectSeller);
    const pageName = useCurrentPageName();

    React.useEffect(() => {
        if (seller && eventsMap) {
            firePageViewEvents({ seller, pageName })(eventsMap);
        }

        // We need to ignore the ESLint rule to enable the consumer to
        // pass us an object inline but only make one call.
        // Essentially, we don't want to send out events on re-renders, only on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fireEvents = useMemo(() => {
        if (!seller) {
            return () => null;
        }

        return fireClickEvents({ seller, pageName });
    }, [seller, pageName]);

    const fireImpressionEvents = useMemo(() => {
        if (!seller) {
            return () => null;
        }

        return firePageViewEvents({ seller, pageName });
    }, [seller, pageName]);

    return {
        fireImpressionEvents,
        fireEvents,
        Providers,
    };
};

function selectSeller(seller: AgencySeller) {
    return seller;
}
