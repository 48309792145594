import { useMemo } from 'react';
import { DATETIME_ELEMENT_STYLES } from '@fiverr-private/localization';
import { useLocalizationContext } from '@fiverr-private/seller_pages_toolbox';
import { useAgencySellerData } from '../useAgencySellerData';
import { AgencySeller } from '../../types';

export const useSellerVacation = () => {
    const sellerData = useAgencySellerData(selectSeller);
    const { dateTimeFormat } = useLocalizationContext();

    const {
        isOnVacation,
        formattedVacationTillDate,
        vacationMessage,
        vacationEndDate,
        vacationStartDate,
        isContactAllowed,
    } = useMemo(() => {
        const isOnVacation = sellerData.isOnVacation;
        const vacationEndDate = isOnVacation ? sellerData.endsAt : null;
        const vacationStartDate = isOnVacation ? sellerData.startsAt : null;
        const formattedVacationTillDate =
            isOnVacation && sellerData.endsAt
                ? dateTimeFormat(new Date(+sellerData.endsAt * 1000), {
                      year: DATETIME_ELEMENT_STYLES.NUMERIC,
                      month: DATETIME_ELEMENT_STYLES.SHORT,
                      day: DATETIME_ELEMENT_STYLES.TWO_DIGIT,
                  })
                : null;

        return {
            isOnVacation,
            isContactAllowed: sellerData.isContactAllowed,
            formattedVacationTillDate,
            vacationEndDate,
            vacationStartDate,
            vacationMessage: sellerData.awayMessage || null,
        };
    }, [sellerData, dateTimeFormat]);

    return {
        isOnVacation,
        isContactAllowed,
        vacationEndDate,
        vacationStartDate,
        formattedVacationTillDate,
        vacationMessage,
    };
};

function selectSeller(seller: AgencySeller) {
    return {
        isOnVacation: seller.isOnVacation,
        isContactAllowed: seller.vacation?.isContactAllowed,
        awayMessage: seller.vacation?.awayMessage,
        endsAt: seller.vacation?.endsAt,
        startsAt: seller.vacation?.startsAt,
    };
}
