import React from 'react';
import { ProBadge, FiverrChoiceBadge } from '@fiverr-private/badges';
import { UrlContext } from '@fiverr-private/seller_pages_toolbox';
import { AgencySeller } from '../../types';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';

export const AgencySellerBadge = () => {
    const { isPro } = useAgencySellerData(selectSellerSlice);
    const isFiverrChoice = UrlContext.isFiverrChoice();

    if (isPro) {
        return <ProBadge isAgency />;
    }

    if (isFiverrChoice) {
        return <FiverrChoiceBadge />;
    }

    return null;
};

function selectSellerSlice({ isPro }: AgencySeller) {
    return {
        isPro,
    };
}
