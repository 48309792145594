import React, { FC } from 'react';
import { LazyComponent } from '@fiverr-private/seller_pages_toolbox';
import { useContactSellerModalData } from '../../hooks/useContactSellerModalData';

const lazyImportModal = () => import(/* webpackChunkName: 'ContactSellerModal' */ './Modal');

export interface CustomContactSellerModalProps {
    showModal: boolean;
    modalProps: ReturnType<typeof useContactSellerModalData>['contactSellerModalProps'];
}

export const CustomContactSellerModal: FC<CustomContactSellerModalProps> = ({ showModal, modalProps }) => {
    if (!showModal) {
        return null;
    }

    return (
        <LazyComponent
            moduleName="EnhancedContactSellerModal"
            lazyImport={lazyImportModal}
            shouldImport={showModal}
            componentProps={modalProps}
        />
    );
};
