import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_LOCALE } from '@fiverr-private/fiverr_context/lib/constants';

export const useShowMachineTranslationButton = () => {
    const { locale } = getContext();

    const isDefaultLocale = locale === DEFAULT_LOCALE;

    return !isDefaultLocale;
};
