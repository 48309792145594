import React from 'react';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { translate } from '@fiverr-private/i18n-react';
import { Container, Grid, GridItem, Stack } from '@fiverr-private/layout_components';
import { Drawer, DrawerHeader, DrawerBody, DrawerContent, DrawerFooter } from '@fiverr-private/overlay';
import { useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { Text, Typography } from '@fiverr-private/typography';
import { mixpanel } from '../../../monitoring/mixpanel';

import {
    CONTACT_BUTTON_POSITIONS,
    contactButtonBiEnrichment,
} from '../../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { AgencyContactButton } from '../../AgencyContactButton';
import { SIZES } from '../../../constants';
import { useShowContactSellerModalUpdater } from '../../../hooks/useShowContactSellerModal';
import { agencyContactButtonClassName } from './styles.ve.css';
import { AllTeamMembersDrawerProps } from './types';
import { AllTeamMembersHeader } from './AllTeamMembersDrawerHeader';

export const AllTeamMembersDrawer = ({
    isOpen,
    onClose,
    members,
    agencyProfileUrl,
    agencyName,
}: AllTeamMembersDrawerProps) => {
    const pageName = useCurrentPageName();
    const setShowContactSellerModal = useShowContactSellerModalUpdater();

    const onContactOpen = () => {
        onClose();
        setShowContactSellerModal(true);
        mixpanel.event('Team members - Contact us clicked');
    };

    const contactButtonProps = {
        biEnrichment: contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.OUR_TEAM_SECTION }),
        size: SIZES.LG,
        source: pageName,
        onContactOpen,
        title: translate('agency_page_perseus.contact_us'),
    };

    const agencyContactButtonProps = {
        source: CONTACT_BUTTON_POSITIONS.ALL_TEAM_MODAL,
        biEnrichment: contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.ALL_TEAM_MODAL }),
    };

    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <DrawerContent position="bottom" closeOnEscapeKey isFullScreen>
                <DrawerHeader>
                    <AllTeamMembersHeader
                        agencyName={agencyName}
                        agencyProfileUrl={agencyProfileUrl}
                        contactButtonProps={contactButtonProps}
                        agencyContactButtonProps={agencyContactButtonProps}
                    />
                </DrawerHeader>
                <DrawerBody>
                    <Container maxWidth="1400px" marginY="0" marginX="auto">
                        <Grid
                            columnGap="8"
                            rowGap="8"
                            templateColumns={{
                                xl: '33%_33%_33%',
                                md: '50%_50%',
                                default: '100%',
                            }}
                        >
                            {members.map(({ name, profileImageUrl, description, role }) => (
                                <GridItem
                                    direction="column"
                                    key={name}
                                    borderColor="grey_500"
                                    borderWidth="sm"
                                    borderRadius="lg"
                                    padding="8"
                                    minHeight="251px"
                                >
                                    <Container display="flex" gap="4" marginBottom="4">
                                        <Avatar size="lg" username={name}>
                                            <AvatarImage alt="Agency member avatar" src={profileImageUrl} />
                                        </Avatar>
                                        <Stack direction="column" justifyContent="flexStart" gap="0">
                                            <Text fontWeight="bold" size="b_lg">
                                                {name}
                                            </Text>
                                            <Text>{role}</Text>
                                        </Stack>
                                    </Container>
                                    <Typography color="grey_1200">{description}</Typography>
                                </GridItem>
                            ))}
                        </Grid>
                    </Container>
                </DrawerBody>
                <Container hidden={{ default: false, sm: true }}>
                    <DrawerFooter>
                        <AgencyContactButton
                            size={SIZES.LG}
                            className={agencyContactButtonClassName}
                            {...agencyContactButtonProps}
                            contactButtonProps={contactButtonProps}
                        />
                    </DrawerFooter>
                </Container>
            </DrawerContent>
        </Drawer>
    );
};
