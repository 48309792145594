import React from 'react';
import { LazyComponent } from '@fiverr-private/seller_pages_toolbox';
import { useContactSellerModalProps } from '../../hooks/useContactSellerModalProps';
import { useShowContactSellerModal } from '../../hooks/useShowContactSellerModal';

const lazyImportModal = () => import(/* webpackChunkName: 'ContactSellerModal' */ './Modal');

const ContactSellerModal = () => {
    const modalProps = useContactSellerModalProps();

    return (
        <LazyComponent
            moduleName="ContactSellerModal"
            lazyImport={lazyImportModal}
            shouldImport
            componentProps={modalProps}
        />
    );
};

export const ContactSellerModalWrapper = () => {
    const showModal = useShowContactSellerModal();

    if (!showModal) {
        return null;
    }

    return <ContactSellerModal />;
};
