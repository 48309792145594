import React, { useState } from 'react';
import axios from 'axios';
import { LazyComponent } from '@fiverr-private/ui_utils';
import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { useAgency } from '../../hooks/useAgency';
import { AgencyService } from '../../types';
import { useIsMounted } from '../../hooks/useIsMounted';

const lazyImport = () => import(/* webpackChunkName: 'AgencyServices' */ './index');

export const LazyServices = () => {
    const { services } = useAgency();
    const [agencyServices, setAgencyServices] = useState<AgencyService[] | null>(null);
    // to prevent setState of unmounted component
    const isMounted = useIsMounted();

    const onError = (error: any) => {
        logger.error(new Error('Failed while rendering the AgencyReviews Component'), { cause: error });
    };

    const beforeLoad = () => {
        axios
            .post(pathfinder('agency_page_api_services'), { services })
            .then((res) => res.data && isMounted() && setAgencyServices(res.data?.agencyServices))
            .catch(onError);
    };

    return (
        <LazyComponent
            beforeLoad={beforeLoad}
            lazyImport={lazyImport}
            onError={onError}
            componentProps={{ agencyServices }}
        />
    );
};
