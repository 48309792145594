import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { GigData, ReviewsData } from '@fiverr-private/seller_pages_toolbox';
import { EngagementBadgeInfo } from '@fiverr-private/loyalty_utils';
import { AgencyRollouts } from '../server/middleware/addRollouts/constants';
import { Agency, AgencySeller, CustomOrderModalData } from './types';

export const agencyAtom = atom<Agency>({} as Agency);

export const agencySellerAtom = atomWithImmer<AgencySeller>(undefined as any);

export const gigsAtom = atom<GigData[]>([]);

export const reviewsAtom = atom<Pick<ReviewsData, 'buying_reviews'> | undefined>(undefined);

export const customOrderModalAtom = atom<CustomOrderModalData>({
    data: null,
    isOpen: false,
});

export const rolloutsAtom = atom<Record<AgencyRollouts, boolean> | undefined>(undefined);

export const showContactSellerModalAtom = atom(false);

export const loyaltyDataAtom = atom<EngagementBadgeInfo | null>(null);
