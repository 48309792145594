import React, { FC, ComponentProps } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { VideoIcon } from '@fiverr-private/icons';
import { Button } from '@fiverr-private/button';

export const BookConsultationButton: FC<Omit<ComponentProps<typeof Button>, 'children'>> = ({
    variant = 'outline',
    ...rest
}) => (
    <Button variant={variant} {...rest}>
        <VideoIcon color="currentColor" />
        <I18n k="agency_page_perseus.book_a_meeting" />
    </Button>
);
