import React from 'react';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Modal } from '@fiverr-private/orca';
import { Text, Typography } from '@fiverr-private/typography';
import { AgencyMember } from '../../../types';

import { modalClass } from './styles.ve.css';

interface TeamMemberDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    teamMember: AgencyMember;
}
export const TeamMemberDetailsModal = ({ isOpen, onClose, teamMember }: TeamMemberDetailsModalProps) => {
    const { name, profileImageUrl, role, description } = teamMember;

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={modalClass}>
            <Modal.Layout>
                <Modal.Header>
                    <I18n k="agency_page_perseus.agency_team.meet_team" />
                    <Modal.CloseBtn />
                </Modal.Header>
                <Modal.Body>
                    <Stack direction="column" gap="4">
                        <Container display="flex" marginTop="2" gap="4">
                            <Avatar size="lg" username={name}>
                                <AvatarImage alt="Agency member avatar" src={profileImageUrl} />
                            </Avatar>
                            <Stack direction="column" justifyContent="center" gap="0">
                                <Text fontWeight="bold" size="b_lg">
                                    {name}
                                </Text>
                                <Text maxWidth="320px">{role}</Text>
                            </Stack>
                        </Container>
                        <Typography color="grey_1000">{description}</Typography>
                    </Stack>
                </Modal.Body>
            </Modal.Layout>
        </Modal>
    );
};
