import React, { useRef, useState } from 'react';
import { useEnteredView } from '@fiverr-private/hooks';
import { translate } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { Container } from '@fiverr-private/layout_components';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { bigQuery, EVENT_TYPE } from '../../monitoring/bigQuery';
import { mixpanel } from '../../monitoring/mixpanel';
import { NotableClients } from '../NotableClients/NotableClients';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the AgencyClients Component'), { cause: error });
};

export const AgencyClients = () => {
    const observableRef = useRef<HTMLDivElement>(null);
    const { notableClients } = useAgencySellerData(selectSellerSlice);
    const [interacted, setInteracted] = useState<boolean>(false);

    useEnteredView(observableRef, {
        threshold: 1,
        onEntered: () => {
            bigQuery.impressionEvent(EVENT_TYPE.AGENCY_AMONG_OUR_CLIENTS_IS_SHOWN);
            mixpanel.event('Notable clients viewed');
        },
    });

    const onHover = () => {
        if (!interacted) {
            bigQuery.impressionEvent(EVENT_TYPE.HOVER_ON_AGENCY_AMONG_OUR_CLIENTS);
            setInteracted(true);
        }
    };

    if (!notableClients || !notableClients.length) {
        return null;
    }

    return (
        <ErrorBoundary onError={onRenderError}>
            <Container ref={observableRef} onMouseOver={onHover}>
                <NotableClients wrapTitle title={translate('agency_page_perseus.among_our_clients')} />
            </Container>
        </ErrorBoundary>
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    notableClients: seller.notableClients,
});
