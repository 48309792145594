import React, { FC, ComponentProps, useEffect } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import {
    BookConsultationDrawerProps,
    LazyBookConsultationDrawer,
    Package,
} from '@fiverr-private/consultation_scheduling_drawer';
import { getContext } from '@fiverr-private/fiverr_context';
import { useLocalizationContext } from '@fiverr-private/seller_pages_toolbox';
import { mixpanel } from '../../monitoring/mixpanel';
import { EVENT_TYPE, bigQuery } from '../../monitoring/bigQuery';
import {
    createClickedOnBookMeetingPayload,
    createClickedOnMeetingDateButtonPayload,
    createClickedOnMeetingDurationButtonPayload,
    createClickedOnMeetingHourButtonPayload,
} from '../../monitoring/bigQuery/createBookMeetingEventsPayload';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { BookConsultationButton } from './BookConsultationButton';

const PACKAGES: Package[] = [
    {
        price: 0,
        session_minutes: 15,
    },
    {
        price: 0,
        session_minutes: 30,
    },
];

export interface BookConsultationProps {
    source?: string;
    buttonProps?: Omit<ComponentProps<typeof BookConsultationButton>, 'onClick' | 'disabled'>;
}

export const BookConsultation: FC<BookConsultationProps> = ({ source, buttonProps }) => {
    const { userId } = getContext();

    const { currency } = useLocalizationContext();

    const { seller, freeConsultationsEligible, accountId } = useAgencySellerData(selectSellerSlice);

    const isConsultationAvailable = freeConsultationsEligible && accountId;

    useEffect(() => {
        if (isConsultationAvailable) {
            bigQuery.impressionEvent(EVENT_TYPE.BOOK_MEETING_BUTTON_IS_SHOWN);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDurationClicked: BookConsultationDrawerProps['onDurationClicked'] = ({ duration }) => {
        bigQuery.clickEvent(
            EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_DURATION_BUTTON,
            createClickedOnMeetingDurationButtonPayload({ duration })
        );
    };

    const onDateClicked: BookConsultationDrawerProps['onDateClicked'] = ({ meetingDate, availableSlots }) => {
        bigQuery.clickEvent(
            EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_DATE_BUTTON,
            createClickedOnMeetingDateButtonPayload({ date: meetingDate, available_slots: availableSlots })
        );
    };

    const onHourClicked: BookConsultationDrawerProps['onHourClicked'] = ({ meetingHour }) => {
        bigQuery.clickEvent(
            EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_HOUR_BUTTON,
            createClickedOnMeetingHourButtonPayload({ hour: meetingHour })
        );
    };

    const onBookMeetingClicked: BookConsultationDrawerProps['onBookMeetingClicked'] = ({
        meetingDate,
        meetingHour,
        duration,
        availableSlots,
        projectDetails,
    }) => {
        bigQuery.clickEvent(
            EVENT_TYPE.CLICKED_ON_AGENCY_MEETING_BOOK_MEETING,
            createClickedOnBookMeetingPayload({
                date: meetingDate,
                hour: meetingHour,
                duration,
                available_slots: availableSlots,
                ...(typeof projectDetails === 'string' ? { project_details: projectDetails } : {}),
            })
        );
    };

    const onBookConsultationButtonClick = () => {
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_AGENCY_BOOK_MEETING);
        mixpanel.event('Book a meeting clicked');
    };

    const onMeetingScheduled: BookConsultationDrawerProps['onMeetingScheduled'] = () => {
        mixpanel.event('Book a meeting success');
    };

    if (!isConsultationAvailable) {
        return null;
    }

    if (!userId) {
        return <BookConsultationButton {...buttonProps} onClick={() => window.openSignUpModal?.({}, { source })} />;
    }

    return (
        <LazyBookConsultationDrawer
            placeholder={<BookConsultationButton {...buttonProps} disabled />}
            componentProps={{
                accountId,
                seller,
                packages: PACKAGES,
                currency,
                onDurationClicked,
                onDateClicked,
                onHourClicked,
                onBookMeetingClicked,
                onMeetingScheduled,
                drawerEducation: <I18n k="agency_page_perseus.book_meeting_drawer.drawer_education" />,
                submittedMessage: (date: string) => (
                    <I18n k="agency_page_perseus.book_meeting_drawer.submitted_message" params={{ date }} />
                ),
                children: <BookConsultationButton {...buttonProps} onClick={onBookConsultationButtonClick} />,
            }}
        />
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    seller: {
        id: seller.user.id,
        name: seller.user.name,
        displayName: seller.user.profile.displayName || undefined,
    },
    freeConsultationsEligible: seller.consultations?.freeConsultationsEligible,
    accountId: seller.schedulingSettings?.accountId,
});
