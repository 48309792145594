import React, { useState, useCallback, useMemo, useRef } from 'react';
import { isEmpty, chain } from 'lodash';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { Button } from '@fiverr-private/button';
import { getContext } from '@fiverr-private/fiverr_context';
import { useEnteredView } from '@fiverr-private/hooks';
import { I18n } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { GigServiceCardListings } from '@fiverr-private/listings';
import { logger } from '@fiverr-private/obs';
import { GigData, isBrowser, useCurrentPageName, useLocalizationContext } from '@fiverr-private/seller_pages_toolbox';
import { Typography } from '@fiverr-private/typography';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { useAgency } from '../../hooks/useAgency';
import { useAgencyGigs } from '../../hooks/useAgencyGigs';
import { bigQuery, EVENT_TYPE } from '../../monitoring/bigQuery';
import { mixpanel } from '../../monitoring/mixpanel';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { useGigsWithoutRating } from './useGigsWithoutRating';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the GigServices Component'), { cause: error });
};

const DEFAULT_COUNT_ON_PAGE = 6;

export const orderByPriceAndPro = (gigs: GigData[]): GigData[] =>
    chain(gigs)
        .orderBy((gig) => gig.price_i, 'desc')
        .orderBy({ is_pro: true }, 'desc')
        .value();

export const AgencyGigs = () => {
    const observableRef = useRef<HTMLDivElement>(null);
    const { queryParameters = {} } = getContext();
    const agency = useAgency();
    const { isMD } = useBreakpoints();
    const currentPageName = useCurrentPageName();
    const { currency } = useLocalizationContext();
    const { displayName, username, isActivationCompleted } = useAgencySellerData(selectSellerSlice);
    const [showAllGigs, setShowAllGigs] = useState(false);
    const gigs = useAgencyGigs();
    const orderedGigs = useMemo(
        () =>
            (showAllGigs ? orderByPriceAndPro(gigs) : orderByPriceAndPro(gigs).slice(0, DEFAULT_COUNT_ON_PAGE)).map(
                (gig) => ({ ...gig, agency: { agencySlug: agency.slug } })
            ),
        [gigs, showAllGigs, agency.slug]
    );
    const orderedGigsWithoutRating = useGigsWithoutRating(orderedGigs);

    const onViewAll = useCallback(() => {
        setShowAllGigs(true);
        bigQuery.clickEvent(EVENT_TYPE.SELLER_PAGE_CLICKED_ON_SHOW_MORE_SERVICE);
    }, []);

    const showAllButton = showAllGigs ? false : gigs.length > DEFAULT_COUNT_ON_PAGE;
    const fiverrChoice = queryParameters?.is_choise === 'true';
    const showGridGigs = isBrowser() ? isMD : true;
    const layout = showGridGigs ? GigServiceCardListings.LAYOUT.GRID : GigServiceCardListings.LAYOUT.LIST_HORIZONTAL;

    useEnteredView(observableRef, {
        threshold: 1,
        onEntered: () => {
            bigQuery.impressionEvent(EVENT_TYPE.AGENCY_POPULAR_PACKAGES_IS_SHOWN, {
                agency: {
                    see_all_packages_exist: showAllButton,
                    gig_ids: gigs.map((gig) => gig.gig_id),
                },
            });
            mixpanel.event('Gigs viewed');
        },
    });

    if (isEmpty(gigs)) {
        return null;
    }

    return (
        <ErrorBoundary onError={onRenderError}>
            <Typography size="h_md" fontWeight="bold" color="grey_1200" marginBottom="5">
                <I18n k="agency_page_perseus.gig_services.title" />
            </Typography>
            <Container ref={observableRef} marginBottom="5">
                <GigServiceCardListings
                    biEnrichment={{
                        domain: {
                            name: 'agencies',
                        },
                    }}
                    isUserPending={!isActivationCompleted}
                    source={currentPageName}
                    currency={currency}
                    items={orderedGigsWithoutRating}
                    layout={layout}
                    fiverrChoice={fiverrChoice}
                    sellerUsername={username}
                    sellerDisplayName={displayName || username}
                />
            </Container>
            {showAllButton && (
                <Container marginTop="5">
                    <Button variant="outline" onClick={onViewAll}>
                        <I18n k="agency_page_perseus.gig_services.view_all" params={{ count: gigs.length }} />
                    </Button>
                </Container>
            )}
        </ErrorBoundary>
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    username: seller.user.name,
    displayName: seller.user.profile.displayName,
    isActivationCompleted: seller.user.isActivationCompleted,
});
