import React from 'react';
import classnames from 'classnames';
import { Rating } from '@fiverr-private/orca';
import * as colors from '@fiverr-private/sass/helpers';
import {
    Tooltip,
    EducationTooltip,
    EducationTooltipAnchor,
    EducationTooltipContent,
    EducationTooltipHeader,
    EducationTooltipBadge,
} from '@fiverr-private/tooltip';
import { Icon } from '@fiverr-private/fit';
import { PencilIcon } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { NewSellerLevelBadge, SellerLevel, formatBigNumbers } from '@fiverr-private/seller_pages_toolbox';
import { useMonitoring } from '../../utils/generalMonitoring';
import { useIsSellerOnline } from '../../hooks/useIsSellerOnline';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { AgencySellerBadge } from '../AgencySellerBadge';
import styles from './index.module.scss';

const DISPLAY_NAME_T_KEY = 'agency_page_perseus.display_name';

export const DATA_TEST_ID = 'pro-badge';

interface ProfileBadgeRatingsProps {
    className?: string;
    usernameClassName?: string;
    canEdit?: boolean;
    source?: string;
    displayNameOnly?: boolean;
    withH1PublicName?: boolean;
    onDisplayNameIconClick?(): void;
    onRatingsClick?(): void;
    showSellerLevel?: boolean;
}

export const ProfileBadgeRatings = ({
    className,
    usernameClassName,
    source,
    displayNameOnly,
    canEdit,
    withH1PublicName = false,
    showSellerLevel = false,
    onDisplayNameIconClick,
    onRatingsClick,
}: ProfileBadgeRatingsProps) => {
    const { fireEvents, Providers } = useMonitoring();

    const { isPro, name, displayName, sellerLevel, rating } = useAgencySellerData(selectSellerSlice);

    const { score, count } = rating || {};
    const isOnline = useIsSellerOnline();

    const showOnlineIndicator = isPro && isOnline;

    const onRatingClickBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'seller page - header - click review',
                source,
            },
        });
        onRatingsClick?.();
    };

    const { publicName, username } = displayNameOnly
        ? { publicName: displayName || name, username: '' }
        : { publicName: displayName || name, username: displayName ? `@${name}` : '' };

    const isTopRated = sellerLevel === SellerLevel.LevelTrs;
    // TODO: review if we actually need `showSellerLevel` prop:
    const displaySellerLevel = showSellerLevel || isTopRated;

    const showRating = !!score && !!count;
    const showProfileRatingSection = showRating || displaySellerLevel;

    const DisplayNameTag = withH1PublicName ? 'h1' : 'div';

    return (
        <>
            <div
                className={classnames(
                    className,
                    styles.profileBadgeRatings,
                    styles.profileRating,
                    'flex flex-items-center flex-start'
                )}
            >
                {showOnlineIndicator && <div className={classnames(styles.onlineCircle, 'm-r-8 flex')} />}
                <div className="flex flex-items-center flex-start p-r-16">
                    <DisplayNameTag
                        aria-label="Public Name"
                        className={classnames(usernameClassName, 'tbody-2 text-bold co-text-darkest')}
                    >
                        {publicName}
                    </DisplayNameTag>
                    {username && (
                        <div
                            aria-label="Username"
                            className={classnames(styles.username, 'm-l-8 tbody-4 co-text-medium')}
                        >
                            {username}
                        </div>
                    )}
                    {canEdit && (
                        <div className="m-l-12">
                            <EducationTooltip tooltipStorageKey="display_name_education">
                                <EducationTooltipAnchor>
                                    <div>
                                        <Tooltip
                                            content={<I18n k="agency_page_perseus.display_name.display_name_tooltip" />}
                                        >
                                            <Icon onClick={onDisplayNameIconClick}>
                                                <PencilIcon color={colors.grey_900} />
                                            </Icon>
                                        </Tooltip>
                                    </div>
                                </EducationTooltipAnchor>
                                <EducationTooltipContent>
                                    <EducationTooltipHeader>
                                        <I18n k={`${DISPLAY_NAME_T_KEY}.education_title`} />
                                        <EducationTooltipBadge />
                                    </EducationTooltipHeader>
                                    <I18n k={`${DISPLAY_NAME_T_KEY}.education_body`} />
                                </EducationTooltipContent>
                            </EducationTooltip>
                        </div>
                    )}
                    <span aria-label="Seller Badge" className="m-l-8">
                        <AgencySellerBadge />
                    </span>
                </div>
            </div>
            {showProfileRatingSection && (
                <Stack
                    gap="3"
                    alignItems="stretch"
                    className={classnames(styles.profileBadgeRatings, styles.profileRating)}
                    withDivider
                >
                    {showRating && (
                        <span aria-label="Rating">
                            <Rating
                                className={classnames(styles.rating)}
                                starClassName={styles.star}
                                scoreClassName={styles.score}
                                theme={(Rating as any).THEMES.ONE_STAR}
                                scoreMinimumFractionDigits={1}
                                scoreMaximumFractionDigits={1}
                                ratingsCountClassName={styles.counts}
                                color={(Rating as any).COLORS.BLACK}
                                score={score}
                                ratingsCount={formatBigNumbers(count || 0)}
                                onRatingsClick={onRatingClickBI}
                            />
                        </span>
                    )}
                    {displaySellerLevel && <NewSellerLevelBadge sellerLevel={sellerLevel} />}
                </Stack>
            )}
        </>
    );
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        rating: seller.rating,
        sellerLevel: seller.sellerLevel,
        isPro: seller.isPro,
        displayName: seller.user.profile.displayName,
        name: seller.user.name,
    };
}
