import React, { FC } from 'react';
import cs from 'classnames';
import { Heading } from '@fiverr-private/typography';
import pathfinder from '@fiverr-private/pathfinder';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { AddEmptyBlock, useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { useAgencySellerData } from '../../../hooks/useAgencySellerData';
import { StickyHeader } from '../../StickyHeader';
import styles from './AddPortfolioBlock.module.scss';

const T_PREFIX = 'agency_page_perseus.portfolio.empty';

export const AddPortfolioBlock: FC = () => {
    const currentPageName = useCurrentPageName();

    const username = useAgencySellerData(({ user: { name } }) => name);

    return (
        <Stack id={StickyHeader.TabName.Work} gap="4" direction="column">
            <Heading as="h4">
                <I18n k="agency_page_perseus.portfolio.title" />
            </Heading>
            <AddEmptyBlock
                className={styles.emptyPortfolio}
                contentWrapperClassName={cs(styles.contentWrapper, 'm-20')}
                leftContent={<div className={styles.image} />}
                description={<I18n k={`${T_PREFIX}.description`} />}
                buttonText={<I18n k={`${T_PREFIX}.add_button`} />}
                buttonHref={pathfinder(
                    'new_user_portfolio',
                    { user_id: username },
                    { query: { source: currentPageName, origin: currentPageName } }
                )}
                mpEvents={{
                    type: 'View portfolio empty state',
                    clickType: 'clicked add portfolio',
                }}
            />
        </Stack>
    );
};
