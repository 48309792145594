import React from 'react';
import { logger } from '@fiverr-private/obs';
import { I18n } from '@fiverr-private/i18n-react';
import { Heading } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { LazyPortfolioWidget } from '@fiverr-private/portfolio';
import { StickyHeader } from '../StickyHeader';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { APP_NAME } from '../../../../configuration/constants';
import { usePortfolio } from '../../hooks/usePortfolio/usePortfolio';
import { useIsSelfView } from '../../hooks/useIsSelfView';
import { AddPortfolioBlock } from './AddPortfolioBlock';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the AgencyPortfolioNew Component'), { cause: error });
};

export const AgencyPortfolioNew = () => {
    const username = useAgencySellerData(({ user: { name } }) => name);

    const isSelfView = useIsSelfView();

    const { hasPortfolio } = usePortfolio();

    if (!hasPortfolio) {
        if (isSelfView) {
            return <AddPortfolioBlock />;
        }

        return null;
    }

    return (
        <ErrorBoundary onError={onRenderError}>
            <Stack id={StickyHeader.TabName.Work} gap="4" direction="column">
                <Heading as="h4">
                    <I18n k="agency_page_perseus.portfolio.title" />
                </Heading>
                <LazyPortfolioWidget
                    componentProps={{
                        biEnrichment: {
                            page: {
                                name: APP_NAME,
                            },
                        },
                        username,
                        cardSize: { md: 'md', lg: 'lg' },
                        thumbnailsPosition: { md: 'right' },
                        thumbnailsAmount: { sm: 5 },
                    }}
                />
            </Stack>
        </ErrorBoundary>
    );
};
