import React, { useState } from 'react';
import axios from 'axios';
import { LazyComponent } from '@fiverr-private/ui_utils';
import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { useAgency } from '../../hooks/useAgency';
import { useIsMounted } from '../../hooks/useIsMounted';

const lazyImport = () => import(/* webpackChunkName: 'AgencyIndustries' */ './index');

export const LazyIndustries = () => {
    const { industries } = useAgency();
    const [agencyIndustries, setAgencyIndustries] = useState<string[] | null>(null);
    const isMounted = useIsMounted();
    const onError = (error: any) => {
        logger.error(new Error('Failed while rendering the AgencyReviews Component'), { cause: error });
    };

    const beforeLoad = () => {
        axios
            .post(pathfinder('agency_page_api_industries'), { industries })
            .then((res) => res.data && isMounted() && setAgencyIndustries(res.data.agencyIndustries))
            .catch(onError);
    };

    return (
        <LazyComponent
            beforeLoad={beforeLoad}
            lazyImport={lazyImport}
            onError={onError}
            componentProps={{ agencyIndustries }}
        />
    );
};
