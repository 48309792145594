import React, { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export const useIsStickyLayoutVisible = (observeHideRef?: React.RefObject<HTMLElement>) => {
    const [isVisible, setIsVisible] = useState(!observeHideRef);

    useEffect(() => {
        if (!observeHideRef || !observeHideRef.current) {
            return;
        }

        const handleScroll = throttle(() => {
            const isScrollBelowThreshold = Boolean(
                observeHideRef.current?.offsetTop && window.scrollY > observeHideRef.current.offsetTop
            );
            setIsVisible(isScrollBelowThreshold);
        }, 300);

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [observeHideRef]);

    return isVisible;
};
