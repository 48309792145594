import React from 'react';
import { BriefDrawerProps } from '../../types';
import { AppWrapper } from '../../Steps';

export const BriefDrawer = ({
    isOpen,
    shouldRefreshData,
    onClose,
    onLoadingChange,
    subCategoryId,
    categoryId,
    seller,
    source,
    componentName,
    onContactSellerClick,
    initialText,
}: BriefDrawerProps) => (
    <AppWrapper
        onClose={onClose}
        onLoadingChange={onLoadingChange}
        subCategoryId={subCategoryId}
        categoryId={categoryId}
        seller={seller}
        shouldRefreshData={shouldRefreshData}
        isOpen={isOpen}
        componentName={componentName}
        source={source}
        initialText={initialText}
        onContactSellerClick={onContactSellerClick}
    />
);
