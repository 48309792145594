import React, { FC } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { PaperPlaneIcon, BriefIcon } from '@fiverr-private/icons';
import { DropdownMenu } from '@fiverr-private/dropdown';
import { Stack } from '@fiverr-private/layout_components';
import { ContactItemButton } from '../ContactItemButton';
import { ContactOptionsRenderComponentProps, RenderInComponent } from '../types';
import { useMostRelevantGigInfo } from '../../AgencyBriefDrawer/useMostRelevantGigInfo';
import { bigQuery, EVENT_TYPE } from '../../../monitoring/bigQuery';
import { ContactBIEnrichment } from '../../../monitoring/bigQuery/createContactButtonEventEnrichment';

export interface ContactOptionsProps extends Omit<ContactOptionsRenderComponentProps, 'triggerButtonProps'> {
    renderIn: RenderInComponent;
    biEnrichment: ContactBIEnrichment;
}

export const ContactOptions: FC<ContactOptionsProps> = ({
    onCreateBriefClick,
    onAskQuestionClick,
    renderIn,
    isAskQuestionDisabled,
    biEnrichment,
}) => {
    const { categoryId, subCategoryId, gigId } = useMostRelevantGigInfo();
    const onCreateBriefImpression = () => {
        const enrichment = {
            gig: {
                id: gigId,
            },
            buyer: {
                request: {
                    category_id: categoryId,
                    subcategory_id: subCategoryId,
                },
            },
        };
        bigQuery.impressionEvent(EVENT_TYPE.CREATE_BRIEF_BUTTON_SHOWN, { ...biEnrichment, enrichment });
    };

    const WrapperComponent =
        renderIn === 'dropdown'
            ? DropdownMenu
            : ({ children }) => (
                  <Stack direction="column" gap="2">
                      {children}
                  </Stack>
              );

    return (
        <WrapperComponent>
            <ContactItemButton
                onImpression={onCreateBriefImpression}
                renderIn={renderIn}
                IconComponent={BriefIcon}
                onClick={onCreateBriefClick}
            >
                <I18n k="agency_page_perseus.create_brief" />
            </ContactItemButton>
            <ContactItemButton
                renderIn={renderIn}
                IconComponent={PaperPlaneIcon}
                onClick={onAskQuestionClick}
                disabled={isAskQuestionDisabled}
            >
                <I18n k="agency_page_perseus.ask_question" />
            </ContactItemButton>
        </WrapperComponent>
    );
};
