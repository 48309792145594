import { useState, useMemo, useCallback } from 'react';
import { raiseError, useContactDataClick, useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { useIsLessThanMdScreen } from '../useIsLessThanMdScreen';
import { useAgencySellerData } from '../useAgencySellerData';
import { AgencySeller } from '../../types';

export const useContactSellerModalData = ({ isAnchorVisible }: { isAnchorVisible: boolean }) => {
    const { userId } = getContext();
    const isLoggedIn = !!userId;
    const [showModal, setShowModal] = useState(false);
    const [activateUserModal, setRenderAndOpenUserActivationModal] = useState(false);
    const currentPageName = useCurrentPageName();
    const { sellerId, username, displayName, isActivationCompleted } = useAgencySellerData(selectSellerSlice);
    const isContactDisabled = String(userId) === sellerId;
    const conversationUrl = pathfinder('conversation', { id: username });
    const userHasPendingState = !isActivationCompleted;
    const isLessThanMdScreen = useIsLessThanMdScreen();

    const { modalInitialData, setIsContactClicked, refetchInitialData, isContactClickReady } = useContactDataClick({
        username,
        isVisible: isAnchorVisible,
        isLoggedIn,
        isMD: !isLessThanMdScreen,
        userHasPendingState,
        onError: () => raiseError(),
    });
    const [hasPriorInteraction, setHasPriorInteraction] = useState(false);

    const shouldRedirectToInbox = useMemo(
        () =>
            typeof window !== 'undefined' &&
            (isLessThanMdScreen || modalInitialData?.priorInteraction || hasPriorInteraction),
        [isLessThanMdScreen, modalInitialData, hasPriorInteraction]
    );
    const redirectToInbox = useCallback(() => window?.location.assign(conversationUrl), [conversationUrl]);

    const onContactOpen = useCallback(() => setShowModal(true), []);
    const onCloseActivateModal = useCallback(() => setRenderAndOpenUserActivationModal(false), []);

    const onContactClick = async () => {
        if (shouldRedirectToInbox) {
            redirectToInbox();
        }

        setIsContactClicked(true);

        const maxWaitingTime = new Promise<undefined>((resolve) => {
            setTimeout(() => resolve(undefined), 500);
        });
        const data = isContactClickReady();

        try {
            const additionalData = await Promise.race([maxWaitingTime, data]);
            if (additionalData !== undefined && 'priorInteraction' in additionalData) {
                setHasPriorInteraction(!!additionalData?.priorInteraction);
            }
        } catch (error) {
            logger.error('GeneralContactSellerModal: Failed to get isContactClickReady data', error as Error);
        }

        if (shouldRedirectToInbox) {
            redirectToInbox();
        }

        if (isLoggedIn) {
            if (userHasPendingState) {
                setRenderAndOpenUserActivationModal(true);
                return;
            }

            onContactOpen();
        } else {
            window.openSignUpModal?.({}, { source: currentPageName });
        }
    };

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const modalProps = useMemo(() => {
        const recipientInfo = modalInitialData?.recipient ?? {};
        const show = showModal && !shouldRedirectToInbox;

        return {
            pageName: currentPageName,
            isOpen: show,
            recipientInfo: {
                ...recipientInfo,
                sellerId,
                displayName,
            },
            attachmentsUploadConfig: modalInitialData?.attachmentsUploadConfig,
            showInfo: true,
            source: 'agency_page_contact_me',
            onSubmitSuccess: refetchInitialData,
            onClose: handleCloseModal,
        };
    }, [
        refetchInitialData,
        modalInitialData,
        handleCloseModal,
        sellerId,
        showModal,
        currentPageName,
        displayName,
        shouldRedirectToInbox,
    ]);

    return {
        isUserModalActivated: activateUserModal,
        onCloseUserModal: onCloseActivateModal,
        showContactSellerModal: showModal,
        contactSellerModalProps: modalProps,
        onContactClick,
        isContactDisabled,
    };
};

const selectSellerSlice = ({ user: { id, name, profile, isActivationCompleted } }: AgencySeller) => ({
    sellerId: id,
    username: name,
    displayName: profile.displayName,
    isActivationCompleted,
});
