interface MeetingEventOptions {
    duration: number;
    date: Date;
    available_slots: { startTime: number; endTime: number }[];
    hour: Date;
    source: string;
    project_details?: string;
}

export const createClickedOnMeetingDurationButtonPayload = (props: Pick<MeetingEventOptions, 'duration'>) => ({
    meeting: props,
});

export const createClickedOnMeetingDateButtonPayload = (
    props: Pick<MeetingEventOptions, 'date' | 'available_slots'>
) => ({
    meeting: props,
});

export const createClickedOnMeetingHourButtonPayload = (props: Pick<MeetingEventOptions, 'hour'>) => ({
    meeting: props,
});

export const createClickedOnBookMeetingPayload = (props: Omit<MeetingEventOptions, 'source'>) => ({
    meeting: { ...props, source: 'agencies' },
});
