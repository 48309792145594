import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { getContext } from '@fiverr-private/fiverr_context';
import { raiseError, useContactDataClick, useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { useShowContactSellerModalUpdater } from '../useShowContactSellerModal';
import { useAgencySellerData } from '../useAgencySellerData';
import { AgencySeller } from '../../types';

export const useContactSellerModalProps = () => {
    const { userId } = getContext();
    const setShowContactSellerModal = useShowContactSellerModalUpdater();
    const { isMD } = useBreakpoints();
    const currentPageName = useCurrentPageName();
    const [isVisible, setIsVisible] = useState(false);
    const { sellerId, username, displayName, isActivationCompleted } = useAgencySellerData(selectSellerSlice);
    const { modalInitialData, refetchInitialData } = useContactDataClick({
        username,
        isVisible,
        isMD,
        isLoggedIn: !!userId,
        userHasPendingState: !isActivationCompleted,
        onError: () => raiseError(),
    });

    useEffect(() => setIsVisible(true), []);

    const handleCloseModal = useCallback(() => setShowContactSellerModal(false), [setShowContactSellerModal]);

    return useMemo(() => {
        const recipientInfo = modalInitialData?.recipient ?? {};
        const show = !isEmpty(modalInitialData);

        return {
            pageName: currentPageName,
            isOpen: show,
            recipientInfo: {
                ...recipientInfo,
                sellerId,
                displayName,
                username,
            },
            attachmentsUploadConfig: modalInitialData?.attachmentsUploadConfig,
            showInfo: true,
            source: 'agency_page_contact_me',
            onSubmitSuccess: refetchInitialData,
            onClose: handleCloseModal,
        };
    }, [currentPageName, refetchInitialData, modalInitialData, handleCloseModal, sellerId, displayName, username]);
};

const selectSellerSlice = ({ user: { id, name, profile, isActivationCompleted } }: AgencySeller) => ({
    sellerId: id,
    username: name,
    displayName: profile.displayName,
    isActivationCompleted,
});
