import React from 'react';
import { Arrows, Carousel, Track, Slide } from '@fiverr-private/carousel';
import { theme } from '@fiverr-private/theme';
import { SkillCheckIcon } from '@fiverr-private/icons';
import { CertifiedTestimonial } from '@fiverr-private/certified_testimonial';
import { Stack } from '@fiverr-private/layout_components';
import { useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { Heading } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { StickyHeader } from '../StickyHeader';
import { testimonialClassName } from './styles.css';

const ApproveIcon = <SkillCheckIcon size={16} color={theme.colors.business_1200} />;

export const Testimonials = () => {
    const { sellerId, testimonials } = useAgencySellerData(selectSellerSlice);
    const pageName = useCurrentPageName();

    const withCompanySpace = testimonials.some((testimonial) => !!testimonial.client?.company);

    const biParams = {
        pageName,
        sellerId,
    };

    if (!testimonials || !testimonials.length) {
        return null;
    }

    return (
        <Stack gap="5" id={StickyHeader.TabName.Testimonials}>
            <Heading as="h5" size="h_md">
                <I18n k="seller_pages_toolbox.testimonials.title" />
            </Heading>
            <Carousel
                breakpoints={{
                    default: 1,
                    md: 2,
                }}
                scrollType="row"
            >
                <Arrows />
                <Track>
                    {testimonials.map((testimonial) => (
                        <Slide key={testimonial.id}>
                            <CertifiedTestimonial
                                {...testimonial}
                                className={testimonialClassName}
                                approveIcon={ApproveIcon}
                                withCompanySpace={withCompanySpace}
                                biParams={biParams}
                            />
                        </Slide>
                    ))}
                </Track>
            </Carousel>
        </Stack>
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    sellerId: seller?.user?.id,
    testimonials: seller?.testimonials,
});
