import { AgencySeller } from '../../types';
import { useAgencySellerData } from '../useAgencySellerData';
import { useIsSelfView } from '../useIsSelfView';

export const usePortfolio = () => {
    const isSelfView = useIsSelfView();
    const { portfolios } = useAgencySellerData(selectSellerSlice);

    const totalCount = portfolios?.totalCount;

    const hasPortfolio = !!totalCount;
    const showPortfolio = isSelfView ? true : hasPortfolio;

    return {
        hasPortfolio,
        showPortfolio,
    };
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        portfolios: seller.portfolios,
    };
}
