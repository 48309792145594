import React, { useRef, useState } from 'react';
import ReactTruncate from 'react-truncate';
import { useObserver } from '@fiverr-private/orca';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Divider, Stack } from '@fiverr-private/layout_components';
import { Link, Text, Typography } from '@fiverr-private/typography';
import { bigQuery, EVENT_TYPE } from '../../../monitoring/bigQuery';
import { mixpanel } from '../../../monitoring/mixpanel';
import { AgencyMember } from '../../../types';
import { TeamMemberDetailsModal } from '../TeamMembersDetailsModal';

import { roleClass, tileClass } from './styles.ve.css';

interface TeamMemberTileProps extends AgencyMember {
    index: number;
    navigationButtonExist: boolean;
}

export const TeamMemberTile = (props: TeamMemberTileProps) => {
    const componentRef = useRef<HTMLElement>(null);
    const [viewed, setViewed] = useState<boolean>(false);
    const [readMoreExist, setReadMoreExist] = useState<boolean>(false);
    const { name, profileImageUrl, role, description, index, navigationButtonExist } = props;
    const containerRef = useRef<HTMLElement>(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

    const openTeamMemberDetails = () => {
        setIsDetailsModalOpen(true);
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_EMPLOYEE_CARD_IN_OUR_TEAM, {
            agency: {
                read_more_on_employee_exist: readMoreExist,
                team_member_details: {
                    position: index + 1,
                },
            },
        });
        mixpanel.event('Team members - Read more clicked');
    };
    const closeTeamMemberDetails = () => setIsDetailsModalOpen(false);
    const onObserve = () => {
        if (!viewed) {
            bigQuery.impressionEvent(EVENT_TYPE.AGENCY_EMPLOYEE_IS_SHOWN, {
                agency: {
                    read_more_on_employee_exist: readMoreExist,
                    navigation_button_exist: navigationButtonExist,
                    team_member_details: {
                        name,
                        position: index + 1,
                    },
                },
            });
            setViewed(true);
        }
    };
    const handleTruncate = (isTruncated) => setReadMoreExist(isTruncated);

    useObserver({
        observableRef: componentRef,
        intersectionThreshold: 1,
        onObserve,
    });

    const ellipsis = (
        <span>
            ...{' '}
            <Link onClick={openTeamMemberDetails} cursor="pointer">
                <I18n k="agency_page_perseus.read_more" />
            </Link>
        </span>
    );

    return (
        <Container display="flex" maxWidth="100%" overflow="hidden" ref={componentRef}>
            {index !== 0 && (
                <Stack marginRight="10">
                    <Divider direction="vertical" height="138px" />
                </Stack>
            )}
            <Stack direction="column" marginRight="6" className={tileClass}>
                <Container display="flex" width="100%" marginBottom="2">
                    <Avatar size="lg" username={name}>
                        <AvatarImage alt="Agency member avatar" src={profileImageUrl} />
                    </Avatar>
                    <Stack
                        direction="column"
                        className={roleClass}
                        marginLeft="4"
                        paddingTop="2"
                        paddingBottom="2"
                        height="64px"
                        gap="0"
                    >
                        <Text fontWeight="semibold" size="b_lg">
                            {name}
                        </Text>
                        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" width="100%">
                            {role}
                        </Text>
                    </Stack>
                </Container>
                <Container maxHeight="48px" position="relative" overflow="hidden" ref={containerRef}>
                    <ReactTruncate lines={2} trimWhitespace ellipsis={ellipsis} onTruncate={handleTruncate}>
                        <Typography paddingTop="1" paddingBottom="1" color="grey_1000">
                            {description}
                        </Typography>
                    </ReactTruncate>
                </Container>
            </Stack>
            <TeamMemberDetailsModal teamMember={props} isOpen={isDetailsModalOpen} onClose={closeTeamMemberDetails} />
        </Container>
    );
};
