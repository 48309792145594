import React, { FC, PropsWithChildren, useState } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    DrawerTrigger,
} from '@fiverr-private/overlay';
import { ContactTriggerButton } from '../ContactTriggerButton';
import { ContactOptions } from '../ContactOptions';
import { ContactOptionsRenderComponentProps } from '../types';

export const ContactDrawer: FC<PropsWithChildren<ContactOptionsRenderComponentProps>> = ({
    triggerButtonProps,
    onCreateBriefClick,
    onAskQuestionClick,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const onCloseDrawer = () => {
        setIsOpen(false);
    };

    const handleCreateBriefClick = () => {
        setIsOpen(false);
        onCreateBriefClick();
    };

    const handleAskQuestionClick = async () => {
        setIsOpen(false);
        await onAskQuestionClick();
    };

    return (
        <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
            <DrawerTrigger>
                <ContactTriggerButton isOptionsShown={isOpen} onClick={setIsOpen} {...triggerButtonProps} />
            </DrawerTrigger>
            <DrawerContent position="bottom" closeOnOverlayClick>
                <DrawerHeader>
                    <I18n k="agency_page_perseus.contact_us" />
                    <DrawerCloseButton />
                </DrawerHeader>
                <DrawerBody>
                    <ContactOptions
                        biEnrichment={triggerButtonProps.biEnrichment}
                        renderIn="drawer"
                        onCreateBriefClick={handleCreateBriefClick}
                        onAskQuestionClick={handleAskQuestionClick}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
