import React, { useState, FC, PropsWithChildren, useRef } from 'react';
import { Dropdown, DropdownAnchor, DropdownContent } from '@fiverr-private/dropdown';
import { useClickOutside } from '@fiverr-private/hooks';
import { ContactTriggerButton } from '../ContactTriggerButton';
import { ContactOptions } from '../ContactOptions';
import { ContactOptionsRenderComponentProps } from '../types';

export const ContactDropdown: FC<PropsWithChildren<ContactOptionsRenderComponentProps>> = ({
    triggerButtonProps,
    onCreateBriefClick,
    onAskQuestionClick,
}) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, () => setIsDropdownActive(false));

    const handleCreateBriefClick = () => {
        setIsDropdownActive(false);
        onCreateBriefClick();
    };

    const handleAskQuestionClick = async () => {
        setIsDropdownActive(false);
        await onAskQuestionClick();
    };

    return (
        <div ref={ref}>
            <Dropdown isOpen={isDropdownActive}>
                <DropdownAnchor>
                    <ContactTriggerButton
                        isOptionsShown={isDropdownActive}
                        onClick={setIsDropdownActive}
                        {...triggerButtonProps}
                    />
                </DropdownAnchor>
                <DropdownContent>
                    <ContactOptions
                        renderIn="dropdown"
                        biEnrichment={triggerButtonProps.biEnrichment}
                        onCreateBriefClick={handleCreateBriefClick}
                        onAskQuestionClick={handleAskQuestionClick}
                    />
                </DropdownContent>
            </Dropdown>
        </div>
    );
};
