import React, { FC } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { Location } from '../../../Location';

import { AllTeamMembersDrawerProps } from '../types';

export interface AgencyNameLocationProps extends Pick<AllTeamMembersDrawerProps, 'agencyName'> {}

export const AgencyNameLocation: FC<AgencyNameLocationProps> = ({ agencyName }) => (
    <Stack direction="column" justifyContent="center" gap="0">
        <Typography fontWeight="bold" size="h_md" color="grey_1200" marginBottom="1">
            <I18n k="agency_page_perseus.agency_team.agency_title" params={{ name: agencyName }} />
        </Typography>
        <Container display="flex" alignItems="center">
            <Typography color="grey_1200">
                <Location />
            </Typography>
        </Container>
    </Stack>
);
