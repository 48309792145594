import React, { useState, FC, ComponentProps } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    ContactButton,
    SUPPORTED_ACTIONS,
    UserActivationModalWrapper,
    useCurrentPageName,
} from '@fiverr-private/seller_pages_toolbox';
import { AgencyBriefDrawer } from '../AgencyBriefDrawer';
import { useIsLessThanMdScreen } from '../../hooks/useIsLessThanMdScreen';
import { useAiBriefAvailablity } from '../../hooks/useAiBriefAvailability';
import { SIZES } from '../../constants';
import {
    ContactBIEnrichment,
    ContactButtonPosition,
} from '../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { mixpanel } from '../../monitoring/mixpanel';
import { EVENT_TYPE, bigQuery } from '../../monitoring/bigQuery';
import { CustomContactSellerModal } from '../ContactSellerModal/CustomContactSellerModal';
import { useContactSellerModalData } from '../../hooks/useContactSellerModalData';
import { ContactDrawer } from './ContactDrawer';
import { ContactDropdown } from './ContactDropdown';

interface ContactWithOptionsButtonProps extends Omit<AgencyContactButtonProps, 'contactButtonProps'> {}

export const ContactWithOptionsButton: FC<ContactWithOptionsButtonProps> = ({
    size,
    source,
    biEnrichment,
    className,
}) => {
    const { userId } = getContext();
    const {
        isUserModalActivated,
        onCloseUserModal,
        contactSellerModalProps,
        showContactSellerModal,
        isContactDisabled,
        onContactClick,
    } = useContactSellerModalData({ isAnchorVisible: true });

    const pageName = useCurrentPageName();
    const isScreenLessThanMedium = useIsLessThanMdScreen();
    const ContactOptionsComponent = isScreenLessThanMedium ? ContactDrawer : ContactDropdown;

    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [isDrawerLoading, setIsDrawerLoading] = useState(false);

    const onCreateBriefClick = () => {
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_CREATE_BRIEF_IN_CONTACT_DROPDOWN, biEnrichment);
        mixpanel.event(`${source} - Contact brief clicked`);

        if (!userId) {
            window.openSignUpModal?.({}, { source: pageName });
            return;
        }

        setIsShowDrawer(true);
        setIsDrawerLoading(true);
    };

    const onAskQuestionClick = async () => {
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_CONTACT_US_IN_DROPDOWN, biEnrichment);
        mixpanel.event(`${source} - Contact us clicked`);

        await onContactClick();
    };

    const closeDrawer = () => {
        setIsShowDrawer(false);
        setIsDrawerLoading(false);
    };

    return (
        <>
            <ContactOptionsComponent
                triggerButtonProps={{ size, className, source, biEnrichment }}
                onAskQuestionClick={onAskQuestionClick}
                onCreateBriefClick={onCreateBriefClick}
                isAskQuestionDisabled={isContactDisabled}
            />
            <AgencyBriefDrawer
                isShow={isShowDrawer}
                isLoading={isDrawerLoading}
                closeDrawer={closeDrawer}
                onLoadingChange={setIsDrawerLoading}
                componentName={source}
            />
            <CustomContactSellerModal showModal={showContactSellerModal} modalProps={contactSellerModalProps} />
            <UserActivationModalWrapper
                isOpen={isUserModalActivated}
                onClose={onCloseUserModal}
                action={SUPPORTED_ACTIONS.CONTACT_SELLER}
            />
        </>
    );
};

export interface AgencyContactButtonProps {
    size?: ValueOf<typeof SIZES>;
    biEnrichment: ContactBIEnrichment;
    className?: string;
    source: ContactButtonPosition;
    contactButtonProps: ComponentProps<typeof ContactButton>;
}

export const AgencyContactButton: FC<AgencyContactButtonProps> = ({ contactButtonProps, ...contactOptionsProps }) => {
    const isAiBriefAvailable = useAiBriefAvailablity();

    return isAiBriefAvailable ? (
        <ContactWithOptionsButton {...contactOptionsProps} />
    ) : (
        <ContactButton {...contactButtonProps} />
    );
};
