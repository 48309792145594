import React from 'react';
import { Divider } from '@fiverr-private/layout_components';
import { Box } from '@fiverr-private/theme';

import { dividerClass } from './styles.ve.css';

export const AgencyOverviewDivider = () => (
    <Box className={dividerClass}>
        <Divider />
    </Box>
);
