import React, { useRef, useState } from 'react';
import { useEnteredView } from '@fiverr-private/hooks';
import { I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { Truncate } from '@fiverr-private/seller_pages_toolbox';
import { Stack } from '@fiverr-private/layout_components';
import { Box } from '@fiverr-private/theme';
import { Typography, Text } from '@fiverr-private/typography';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { bigQuery, EVENT_TYPE } from '../../monitoring/bigQuery';
import { useAgency } from '../../hooks/useAgency';
import { mixpanel } from '../../monitoring/mixpanel';
import { StickyHeader } from '../StickyHeader';
import { Location } from '../Location';
import { Languages } from '../Languages';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { EmployeesCount } from './EmployeesCount';
import { EstablishedYear } from './EstablishedYear';
import { infoWrClass } from './styles.ve.css';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the AgencyAbout Component'), { cause: error });
};

export const AgencyAbout = () => {
    const observableRef = useRef<HTMLDivElement>(null);
    const agency = useAgency();
    const [readMoreExist, setReadMoreExist] = useState<string>();
    const { description } = useAgencySellerData(selectSellerSlice);

    const handleReadMoreClick = () => {
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_READ_MORE_IN_ABOUT_OUR_AGENCY);

        mixpanel.event('About us - Read more clicked');
    };

    const handleTruncate = (isTruncated: boolean) => setReadMoreExist(`${isTruncated}`);

    useEnteredView(observableRef, {
        threshold: 1,
        onEntered: () => {
            bigQuery.impressionEvent(EVENT_TYPE.ABOUT_OUR_AGENCY_IS_SHOWN, {
                agency: {
                    show_more_exist: readMoreExist === 'true',
                },
            });
            mixpanel.event('About us - viewed');
        },
    });

    return (
        <ErrorBoundary onError={onRenderError}>
            <Box ref={observableRef} id={StickyHeader.TabName.About}>
                <Typography size="h_md" fontWeight="bold" color="grey_1200" marginBottom="4">
                    <I18n k="agency_page_perseus.about_agency" />
                </Typography>
                <Stack alignItems="center" gap="0" wrap="wrap" marginBottom="4" className={infoWrClass}>
                    <Box marginBottom="2" marginRight="6">
                        <Text size="b_lg" fontWeight="semibold" color="bodyPrimary">
                            <Location />
                        </Text>
                    </Box>
                    <Box marginBottom="2" marginRight="6">
                        <Typography size="b_lg" fontWeight="semibold" color="grey_1200">
                            <Languages />
                        </Typography>
                    </Box>
                    <Box marginBottom="2" marginRight="6">
                        <EmployeesCount count={agency.teamSize || 0} />
                    </Box>
                    <Box marginBottom="2" marginRight="6">
                        <EstablishedYear year={agency.established as number} />
                    </Box>
                </Stack>
                <Typography
                    size="b_lg"
                    color="grey_1100"
                    maxWidth="787px"
                    marginTop="4"
                    data-testid="agencyDescription"
                >
                    <Truncate lines={3} onReadMore={handleReadMoreClick} onTruncate={handleTruncate}>
                        {description}
                    </Truncate>
                </Typography>
            </Box>
        </ErrorBoundary>
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    description: seller.description,
    languages: seller.user.languages,
});
