import React from 'react';
import OutOfOffice from '@fiverr-private/out_of_office';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { useIsSelfView } from '../../hooks/useIsSelfView';
import { useSellerVacation } from '../../hooks/useSellerVacation/useSellerVacation';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';

const onRenderError = (originalError) => {
    const err = new Error('Failed while rendering the VacationMessageBanner Component');
    logger.error(err, {
        cause: originalError,
    });
};

export const VacationMessageBanner = () => {
    const isCurrentUser = useIsSelfView();
    const { username, sellerId, displayName } = useAgencySellerData(selectSellerSlice);
    const { isOnVacation, vacationMessage, vacationEndDate, vacationStartDate, isContactAllowed } = useSellerVacation();

    if (!isOnVacation) {
        return null;
    }

    const outOfOfficeProps = {
        username,
        user_id: sellerId,
        displayName,
        is_on_vacation: isOnVacation,
        start_date: vacationStartDate,
        end_date: vacationEndDate,
        message_for_buyer: vacationMessage,
        is_page_owner: isCurrentUser,
        allow_contact: isContactAllowed,
        vacation_status: isOnVacation ? 'active' : 'historic',
    };

    return (
        <ErrorBoundary onError={onRenderError}>
            <section className="m-b-40">
                <OutOfOffice {...outOfOfficeProps} />
            </section>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        sellerId: seller.user.id,
        username: seller.user.name,
        displayName: seller.user.profile.displayName,
    };
}
