import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { MessageIcon } from '@fiverr-private/icons';
import { translate } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { theme } from '@fiverr-private/theme';
import { LanguageCode, LanguageProficiencyLevel } from '@fiverr-private/seller_pages_toolbox';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';

const getLanguage = (codeName: LanguageCode) => translate(`languages.codes.${codeName.toLowerCase()}`);

export const Languages = () => {
    const { languages } = useAgencySellerData(selectSellerSlice);

    const validLanguages = useMemo(
        () => languages.filter((item) => item.level !== LanguageProficiencyLevel.Basic),
        [languages]
    );

    if (isEmpty(validLanguages)) {
        return null;
    }

    return (
        <Stack gap="1" alignItems="center">
            <MessageIcon size={16} color={theme.colors.grey_1200} />
            <Text size="b_lg" fontWeight="semibold" color="bodyPrimary">
                {validLanguages.map((lang) => getLanguage(lang.code)).join(', ')}
            </Text>
        </Stack>
    );
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        languages: seller.user.languages,
    };
}
