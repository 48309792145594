import React, { useEffect, useMemo, useRef, useState } from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Textarea } from '@fiverr-private/forms';
import { Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useBriefContext } from '../../../common/BriefContext';
import { useBriefPlaceholder } from '../../../hooks/useBriefPlaceholder';
import { MAX_PROMPT_LENGTH, MIN_PROMPT_LENGTH } from '../Validation';
import { ErrorMessage } from './ErrorMessage';

import * as styles from './styles.ve.css';

export const InputPrompt = () => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const {
        userInput,
        setUserInput,
        categoryId,
        subCategoryId,
        inputValidations: { isDebounceInputValid, failedValidation },
        isBriefDrawerDisplayed,
    } = useBriefContext();
    const { data, isLoading, isError } = useBriefPlaceholder({ categoryId, subCategoryId });
    const [shouldShowValidationErrors, setShouldShowValidationErrors] = useState(false);

    const placeholder = useMemo(() => {
        const defaultPlaceholder = translate(`ai_brief.prompt_step.placeholder.default`);
        if (isError || !data?.generation_example || isLoading) {
            return defaultPlaceholder;
        }
        return data?.generation_example;
    }, [data, isError, isLoading]);

    useEffect(() => {
        if (isBriefDrawerDisplayed) {
            textareaRef.current?.focus();
            textareaRef.current?.setSelectionRange(userInput?.length, userInput?.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBriefDrawerDisplayed]);

    return (
        <>
            <Textarea
                ref={textareaRef}
                className={styles.textarea}
                value={userInput}
                // defaultValue={initialText}
                placeholder={placeholder}
                onChange={(e) => setUserInput(e.target.value)}
                minLength={MIN_PROMPT_LENGTH}
                maxLength={MAX_PROMPT_LENGTH}
                isError={!isDebounceInputValid || !!failedValidation?.errorMessage}
                onFocus={() => setShouldShowValidationErrors(false)}
                onBlur={() => setShouldShowValidationErrors(true)}
            />
            <Container display="flex" justifyContent="spaceBetween" alignItems="flexEnd">
                {shouldShowValidationErrors && <ErrorMessage failedValidation={failedValidation} />}
                <Text
                    marginLeft="auto"
                    color={failedValidation?.errorMessage ? 'validationError' : 'bodySecondary'}
                    size="b_sm"
                    className={styles.characters}
                ></Text>
            </Container>
        </>
    );
};
