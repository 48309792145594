import React, { FC } from 'react';
import { useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { BriefDrawer } from '@fiverr-private/ai_brief';
import { ContactButtonPosition } from '../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { AgencySeller } from '../../types';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { useMostRelevantGigInfo } from './useMostRelevantGigInfo';

export interface AgencyBriefDrawerProps {
    isShow: boolean;
    isLoading: boolean;
    closeDrawer: () => void;
    onLoadingChange: (isLoading: boolean) => void;
    componentName: ContactButtonPosition;
}

export const AgencyBriefDrawer: FC<AgencyBriefDrawerProps> = ({
    isShow,
    isLoading,
    closeDrawer,
    onLoadingChange,
    componentName,
}) => {
    const seller = useAgencySellerData(selectSellerSlice);

    const { categoryId, subCategoryId } = useMostRelevantGigInfo();

    const source = useCurrentPageName();

    if (!categoryId || !subCategoryId) {
        return null;
    }

    return (
        <BriefDrawer
            seller={seller}
            shouldRefreshData={isShow}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            isOpen={isShow && !isLoading}
            onClose={closeDrawer}
            onLoadingChange={onLoadingChange}
            source={source}
            componentName={componentName}
        />
    );
};

const selectSellerSlice = ({ user: { id, name, profile }, responseTime }: AgencySeller) => ({
    id: Number(id),
    userName: name,
    displayName: profile.displayName ?? name,
    responseTime: responseTime?.inHours ?? 1,
});
