import React from 'react';
import { isEmpty } from 'lodash';
import { RESOURCE_TYPES } from '@fiverr-private/collect/src/utils/constants';
import { SaveToListPopover, SaveToListButton as BaseSaveToListButton } from '@fiverr-private/collect_actions';
import { useAgencySellerData } from '../../../hooks/useAgencySellerData';
import { AgencySeller } from '../../../types';

export const SaveAgencyButton = () => {
    const { sellerId } = useAgencySellerData(selectSellerSlice);

    return (
        <SaveToListPopover
            resourceType={RESOURCE_TYPES.SELLER}
            resourceId={sellerId}
            position="bottom-end"
            renderTrigger={({ inCollections }) => (
                <BaseSaveToListButton variant="icon" checked={!isEmpty(inCollections)} />
            )}
        />
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    sellerId: seller.user.id,
});
