import React, { useCallback } from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { UGCTranslateButton, useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { useAgency, useAgencyUpdater } from '../../hooks/useAgency';
import { useShowMachineTranslationButton } from '../../hooks/useShowMachineTranslationButton';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { useAgencySellerDataUpdater } from '../../hooks/useAgencySellerDataUpdater';

export const MachineTranslationButton = () => {
    const pageName = useCurrentPageName();
    const agency = useAgency();
    const setAgencyData = useAgencyUpdater();
    const seller = useAgencySellerData(selectSellerSlice);
    const setSellerData = useAgencySellerDataUpdater();
    const isMachineTranslationButtonVisible = useShowMachineTranslationButton();

    const onTranslationSuccess = useCallback(
        (
            {
                oneLinerTitle = '',
                description = '',
                vacationMessage = '',
                notableClients = [],
                teamMembers = [],
            } = {} as any
        ) => {
            const existingNotableClients = seller?.notableClients;
            const updatedNotableClients = Array.isArray(existingNotableClients)
                ? existingNotableClients.map((client, i) => ({
                      ...client,
                      description: notableClients[i]?.description,
                      company: client.company && {
                          ...client.company,
                          description: notableClients[i]?.companyDescription,
                      },
                  }))
                : [];

            setSellerData((sellerToUpdate: AgencySeller) => {
                sellerToUpdate.description = description;
                sellerToUpdate.oneLinerTitle = oneLinerTitle;
                sellerToUpdate.notableClients = updatedNotableClients;
                sellerToUpdate.vacation = seller.vacation && {
                    ...seller.vacation,
                    awayMessage: vacationMessage,
                };
            });

            const existingTeamMembers = agency.members;
            const updatedTeamMembers = Array.isArray(existingTeamMembers)
                ? existingTeamMembers.map((member, i) => ({
                      ...member,
                      role: teamMembers[i]?.role,
                      description: teamMembers[i]?.description,
                  }))
                : [];

            setAgencyData({
                ...agency,
                members: updatedTeamMembers,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getDataToTranslate = useCallback(
        () => ({
            description: seller?.description,
            oneLinerTitle: seller.oneLinerTitle,
            vacationMessage: seller?.vacation?.awayMessage,
            notableClients: (seller.notableClients || []).map((client) => ({
                description: client.description || '',
                companyDescription: client.company?.description || '',
            })),
            teamMembers: (agency.members || []).map(({ role, description }) => ({
                role,
                description,
            })),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (!isMachineTranslationButtonVisible) {
        return null;
    }

    return (
        <UGCTranslateButton
            sourceName={pageName}
            componentName={pageName}
            getUgc={getDataToTranslate}
            onSuccess={onTranslationSuccess}
            translateURL={pathfinder('agency_page_api_ugc_translate')}
        />
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    certifications: seller.certifications,
    description: seller.description,
    isPro: seller.isPro,
    notableClients: seller.notableClients,
    oneLinerTitle: seller.oneLinerTitle,
    vacation: seller.vacation,
});
