import React, { PropsWithChildren, FC } from 'react';
import { Stack, Center } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { ArrowRightIcon, SystemIconType } from '@fiverr-private/icons';
import { MenuItem } from '@fiverr-private/dropdown';
import { useIsLessThanSmScreen } from '../../../hooks/useIsLessThanSmScreen';
import { RenderInComponent } from '../types';
import { buttonClassName } from './styles.ve.css';

interface ContactItemButtonProps {
    IconComponent: ({ size, color }: SystemIconType) => React.ReactElement;
    onClick: () => void;
    renderIn: RenderInComponent;
    disabled?: boolean;
    onImpression?: () => void;
}

export const ContactItemButton: FC<PropsWithChildren<ContactItemButtonProps>> = ({
    IconComponent,
    children,
    onClick,
    renderIn,
    disabled,
    onImpression,
}) => {
    const [contactItemRef] = useImpressionItem({
        sendImpressionToBQ: false,
        onImpression,
    });

    const isScreenLessThanSm = useIsLessThanSmScreen();
    const WrapperComponent = renderIn === 'dropdown' ? MenuItem : 'button';
    const wrapperClassName = renderIn === 'dropdown' ? undefined : buttonClassName;
    const minWidth = isScreenLessThanSm ? 280 : 320;

    return (
        <WrapperComponent onClick={onClick} className={wrapperClassName} disabled={disabled}>
            <Stack alignItems="center" gap="4" minWidth={minWidth} ref={contactItemRef}>
                <Center borderRadius="circle" backgroundColor="grey_200" width={40} height={40}>
                    <IconComponent color="currentColor" size={20} />
                </Center>
                <Stack justifyContent="spaceBetween" alignItems="center" flex={1}>
                    <Text>{children}</Text>
                    <ArrowRightIcon color="currentColor" />
                </Stack>
            </Stack>
        </WrapperComponent>
    );
};
