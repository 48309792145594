import { useEffect, useLayoutEffect, useState } from 'react';
import { identity, isEmpty, throttle } from 'lodash';
import { getWindow } from '@fiverr-private/seller_pages_toolbox';

/**
 * Custom hook for observing scroll behavior and interacting with header tabs.
 *
 * @param {Object} options - Options for the hook.
 * @param {boolean} options.isHeaderActive - Flag indicating whether the header is active.
 * @param {number} options.headerHeight - Height of the header.
 * @param {string[]} options.headerTabIds - Array of header tab IDs.
 * @param {(id: string) => void} options.onHeaderTabIntersect - Callback function when a header tab intersects with the viewport.
 */
export const useScrollObserve = ({
    isHeaderActive,
    headerHeight,
    headerTabIds,
    onHeaderTabIntersect,
}: {
    isHeaderActive: boolean;
    headerHeight: number;
    headerTabIds: string[];
    onHeaderTabIntersect: (id: string) => void;
}) => {
    /**
     * State to store the mapping of scroll positions to header tab IDs.
     */
    const [scrollMap, setScrollMap] = useState<Record<number, string>>({});

    /**
     * Effect to update the scrollMap based on headerTabIds and headerHeight.
     */
    useEffect(() => {
        if (!isHeaderActive) {
            return;
        }

        /**
         * Mapping of header tab IDs to their corresponding scroll positions.
         */
        const elementsMap: Record<string, string> = headerTabIds.reduce((acc, id) => {
            const tab = document.getElementById(id) as HTMLElement;

            if (tab) {
                const scrollToPosition = tab.offsetTop - headerHeight;
                acc[scrollToPosition] = id;
            }

            return acc;
        }, {});

        setScrollMap(elementsMap);
    }, [isHeaderActive, headerHeight, headerTabIds]);

    /**
     * Layout effect to handle scroll events and trigger tab intersection callbacks.
     */
    useLayoutEffect(() => {
        /**
         * Throttled scroll event handler.
         */
        const handleScroll = throttle(() => {
            if (isEmpty(scrollMap) || !getWindow()) {
                return;
            }

            const scrollToPositions = Object.keys(scrollMap).map(Number);

            /**
             * Find the current scroll position and corresponding tab ID.
             * - isFirstSection - If the scroll position is less than the first tab's scroll position.
             * - isMiddleSection - If the scroll position is between two tab's scroll positions.
             * - isLastSection - If the scroll position is greater than the last tab's scroll position.
             */
            const tabScroll = scrollToPositions.find((from, i) => {
                const to = scrollToPositions[i + 1];
                const isFirstSection = i === 0 && window.scrollY < from;
                const isMiddleSection = to && window.scrollY >= from && window.scrollY < to;
                const isLastSection = !to && i + 1 === scrollToPositions.length && window.scrollY >= from;

                return [isFirstSection, isMiddleSection, isLastSection].find(identity);
            }) as number;

            const currentTab = scrollMap[tabScroll];
            onHeaderTabIntersect(currentTab);
        }, 500);

        getWindow()?.addEventListener('scroll', handleScroll);

        /**
         * Cleanup function to remove the scroll event listener.
         */
        return () => {
            handleScroll.cancel();

            getWindow()?.removeEventListener('scroll', handleScroll);
        };
    }, [scrollMap, onHeaderTabIntersect]);
};
