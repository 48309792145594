import React, { useEffect, PropsWithChildren, FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { logger } from '@fiverr-private/obs';
import { Stepper } from '@fiverr-private/orca';
import { AppProvider, useBriefContext } from '../common/BriefContext';
import { briefTemplatesQueryKey, QueryProvider } from '../common/queryClient';
import { STEPS_IDS } from '../common/constants';
import { useBriefShownEvent } from '../hooks/useBriefShownEvent';
import { getBriefTemplates } from '../services';
import { BriefDrawerProps, BriefItem } from '../types';
import { DoneScreen, EditBrief, GenerateStep, GenerationErrorPage, HomeScreen, Loading } from '../components';
import { DrawerRender } from './DrawerRender';

const steps = [
    { id: STEPS_IDS.home },
    { id: STEPS_IDS.loading },
    { id: STEPS_IDS.generate },
    { id: STEPS_IDS.editBrief },
    { id: STEPS_IDS.generationError },
    { id: STEPS_IDS.done },
];

export const AppWrapper = ({
    seller,
    onClose,
    subCategoryId,
    categoryId,
    onLoadingChange,
    shouldRefreshData,
    isOpen,
    source,
    componentName,
    onContactSellerClick,
    initialText = '',
}: BriefDrawerProps) => (
    <QueryProvider>
        <AppProvider
            onClose={onClose}
            isBriefDrawerOpen={isOpen}
            subCategoryId={subCategoryId}
            categoryId={categoryId}
            source={source}
            seller={seller}
            componentName={componentName}
            onContactSellerClick={onContactSellerClick}
            initialText={initialText}
        >
            <Stepper steps={steps}>
                {() => (
                    <BriefStepsWrapper onLoadingChange={onLoadingChange} shouldRefreshData={shouldRefreshData}>
                        <DrawerRender isOpen={isOpen} sellerId={seller.id}>
                            <Stepper.Step stepId={STEPS_IDS.home}>
                                <HomeScreen />
                            </Stepper.Step>
                            <Stepper.Step stepId={STEPS_IDS.loading}>
                                <Loading />
                            </Stepper.Step>
                            <Stepper.Step stepId={STEPS_IDS.generate}>
                                <GenerateStep />
                            </Stepper.Step>
                            <Stepper.Step stepId={STEPS_IDS.editBrief}>
                                <EditBrief sellerId={seller.id} />
                            </Stepper.Step>
                            <Stepper.Step stepId={STEPS_IDS.generationError}>
                                <GenerationErrorPage />
                            </Stepper.Step>
                            <Stepper.Step stepId={STEPS_IDS.done}>
                                <DoneScreen seller={seller} />
                            </Stepper.Step>
                        </DrawerRender>
                    </BriefStepsWrapper>
                )}
            </Stepper>
        </AppProvider>
    </QueryProvider>
);

interface BriefStepsWrapperProps {
    shouldRefreshData: boolean;
    onLoadingChange: BriefDrawerProps['onLoadingChange'];
}
const BriefStepsWrapper: FC<PropsWithChildren<BriefStepsWrapperProps>> = ({
    onLoadingChange,
    shouldRefreshData,
    children,
}) => {
    const { navigateById } = Stepper.useContext();
    const queryCache = useQueryClient();

    const {
        setIsBriefInEditMode,
        setUserInput,
        setLastIncludedSection,
        setEditedBrief,
        isBriefDrawerDisplayed,
        initialText = '',
    } = useBriefContext();

    const { isLoading, error, data } = useQuery<BriefItem[]>(briefTemplatesQueryKey, getBriefTemplates, {
        retry: 10,
        staleTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: shouldRefreshData,
    });

    const shouldRedirectToHomePage = !isLoading && shouldRefreshData && !!data?.length && !initialText;

    useBriefShownEvent(shouldRefreshData, shouldRedirectToHomePage);

    useEffect(() => {
        if (isBriefDrawerDisplayed) {
            // Defer the data refresh to the end of close animation
            return;
        }

        if (!shouldRefreshData) {
            setIsBriefInEditMode(false);
            setUserInput(initialText);
            setLastIncludedSection('');
            setEditedBrief(null);
            queryCache.removeQueries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shouldRefreshData,
        queryCache,
        setUserInput,
        setIsBriefInEditMode,
        setLastIncludedSection,
        setEditedBrief,
        isBriefDrawerDisplayed,
    ]);

    useEffect(() => {
        if (isBriefDrawerDisplayed) {
            // Defer the data refresh to the end of close animation
            return;
        }

        if (shouldRedirectToHomePage) {
            navigateById(STEPS_IDS.home);
        } else {
            navigateById(STEPS_IDS.generate);
        }
    }, [shouldRedirectToHomePage, navigateById, isBriefDrawerDisplayed]);

    useEffect(() => {
        onLoadingChange?.(isLoading);
    }, [onLoadingChange, isLoading]);

    if (isLoading) {
        return null;
    }
    if (error) {
        logger.error(new Error('Failed while rendering the Brief-AI and loading brief templates'), {
            cause: error,
        });
        return null;
    }

    return <span>{children}</span>;
};
