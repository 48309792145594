import { getContext } from '@fiverr-private/fiverr_context';
import { useAgencySellerData } from '../useAgencySellerData';
import { AgencySeller } from '../../types';

export const useIsSelfView = () => {
    const { sellerId } = useAgencySellerData(selectSellerSlice);
    const { userId, queryParameters } = getContext();
    const selfViewParam = queryParameters?.up_rollout === 'true';

    return `${userId}` === `${sellerId}` && selfViewParam;
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        sellerId: seller.user.id,
    };
}
