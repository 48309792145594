import React, { useEffect, useRef, LegacyRef } from 'react';
import classnames from 'classnames';
import { useIntersectionObserver } from '@fiverr-private/hooks';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { NotableClientsBar, NotableClientsAbout } from '@fiverr-private/seller_presence';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { useCurrentPageName } from '@fiverr-private/seller_pages_toolbox';
import { useMonitoring } from '../../utils/generalMonitoring';
import './notable-clients.scss';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { NotableClientsProps } from './types';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the NotableClients Component`);
    logger.error(err, {
        cause: originalError,
    });
};

export const NotableClients = ({ className, wrapTitle, verticalOnSm, title }: NotableClientsProps) => {
    const { isMD } = useBreakpoints();
    const { fireEvents, Providers } = useMonitoring();
    const source = useCurrentPageName();

    useEffect(() => {
        fireEvents({
            [Providers.BigQuery]: {
                type: 'seller_page_notable_clients_component_is_shown',
                event_source: source,
            },
        });
    }, [source, Providers.BigQuery, fireEvents]);

    const onHoverBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'hover on notable clients',
                source,
            },
        });
    };

    const observableRef = useRef<HTMLElement>(null);
    const entry = useIntersectionObserver(observableRef, { threshold: 1 });
    const isVisible = Boolean(entry?.isIntersecting);
    const { username, profileImageUrl, notableClients } = useAgencySellerData(selectSellerSlice);

    useEffect(() => {
        if (isVisible) {
            fireEvents({
                [Providers.Mixpanel]: {
                    type: 'seller page - notable clients viewed',
                },
            });
        }
    }, [isVisible, Providers.Mixpanel, fireEvents]);

    const Component = verticalOnSm && !isMD ? NotableClientsAbout : NotableClientsBar;

    const titleProps = {
        title,
        newBadge: false,
        infoIcon: false,
        educate: false,
        classNames: {
            wrapper: wrapTitle ? 'm-b-16' : null,
            title: wrapTitle ? 'tbody-2 co-text-darkest' : null,
        },
    };

    const clients = notableClients.map(({ endedAt, startedAt, description, company }) => ({
        ended_at: endedAt ? Number(endedAt) * 1000 : null,
        started_at: startedAt ? Number(startedAt) * 1000 : null,
        interaction_description: description,
        enriched_details: {
            description: company.description,
            in_fortune: company.inFortuneList,
            logo: company.logoImage?.previewUrl?.url,
            name: company.name,
            industry: company?.industry?.slug ?? '',
        },
    }));

    return (
        <ErrorBoundary onError={onRenderError}>
            <div
                ref={observableRef as LegacyRef<HTMLDivElement>}
                className={classnames(className, 'seller-notable-clients', { 'wrap-title': wrapTitle })}
                onMouseEnter={onHoverBI}
            >
                <Component
                    notableClients={clients}
                    titleProps={titleProps}
                    seller={{ name: username, profilePhoto: profileImageUrl }}
                    hideClientPopover={!isMD}
                    isSlimDesign={wrapTitle}
                    wrap={wrapTitle}
                />
            </div>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        notableClients: seller.notableClients,
        username: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
