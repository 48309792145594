import React from 'react';
import { GetBriefResponseDTOError } from './services';
import { BriefContextValues } from './common/BriefContext';
import {
    BriefSectionContentProps,
    BriefSectionViewContentProps,
} from './components/EditBrief/IncludedSections/BriefSection/SectionTypes';
import type { CustomSectionTitleComponentProps } from './components/common/SectionTitle';

export enum SectionTypes {
    shortText = 'shortText',
    attachments = 'attachments',
    dynamic = 'dynamic',
}

export type SectionInputTypes = 'textarea' | 'input';

export interface BriefSection {
    focused?: boolean;
    TitleComponent?: React.FC<CustomSectionTitleComponentProps>;
    title: string;
    content: string;
    type?: SectionTypes;
    attachments?: ConnectedAttachmentData[] | FileAttachmentData[];
    // suggestion: string; // Phase 2
    included: boolean;
    readOnly?: boolean;
    group?: string;
    inline?: boolean;
    mandatory?: boolean;
    hideActions?: boolean;
    onEditStart?: (content: BriefSection) => void;
    onEditEnd?: (section: BriefSection) => void;
    onAdd?: (section: BriefSection) => void;
    onHide?: (section: BriefSection) => void;
}

export interface DynamicSection extends BriefSection {
    ViewComponent: React.FC<BriefSectionViewContentProps>;
    EditComponent: React.FC<BriefSectionContentProps>;
    content: any;
    mandatoryType?: string;
}

export interface Brief {
    id?: string;
    title: string;
    deletedAttachmentIds?: string[];
    sections: BriefSection[];
    error?: GetBriefResponseDTOError;
}

export interface BriefItem {
    id: string;
    title: string;
    userDisplayName: string;
    createDate: Date;
}

export interface Seller {
    id: number;
    userName: string;
    displayName: string;
    responseTime: number;
}

export interface BusinessInfo {
    companyName: string;
    companyDescription: string;
    organizationType: string;
    businessStage: string;
    numberOfEmployees: string;
    industryTypes: string[];
    companyWebsite: string;
    companyLogo: string;
    industryIds: string[];
    businessType: string | null;
}

export type StringifiedNumber = string;

export interface FileAttachmentData {
    id: string;
    attachment_id?: string;
    provider_id?: string;
    content_type?: string;
    created_at?: string;
    type: string;
    file_size?: number;
    fileName: string;
    preview_type?: string;
    file_name?: string;
    sortId: StringifiedNumber;
    error?: string;
    progress?: number;
    fileSize: string;
    size: number;
    extension: string;
    name?: string;
    loaded?: boolean;
    attachment_name?: string;
    preview_state?: string;
    coverImageUrl?: string;
    preview_urls?: {
        original?: string;
        large?: string;
        thumb_large?: string;
        mobile_preview?: string;
        mobile_static_preview?: string;
    };
    watermarked_preview_urls?: {
        original: string;
        large: string;
        thumb_large: string;
        mobile_preview: string;
        mobile_static_preview: string;
    };
    open_url?: string | null;
    video_urls?: { [key: string]: string };
    download_url?: string;
    watermarked_download_url?: string;
    antivirus_result?: string | null;
    pending_attachment_id?: string;
}

export type ConnectedAttachmentData = FileAttachmentData;

export interface BriefDrawerProps {
    isOpen: boolean;
    shouldRefreshData: boolean;
    onClose: () => void;
    subCategoryId: BriefContextValues['subCategoryId'];
    categoryId: BriefContextValues['categoryId'];
    onLoadingChange?: (isLoading: boolean) => void;
    seller: Seller;
    source: string;
    componentName: string;
    onContactSellerClick?: () => void;
    initialText?: string;
}
