import React, { FC } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { DrawerCloseButton } from '@fiverr-private/overlay';

import { AgencyContactButton, AgencyContactButtonProps } from '../../../AgencyContactButton';
import { AllTeamMembersDrawerProps } from '../types';
import { AgencyNameLocation } from '../AgencyNameLocation';
import { rectangleAvatar } from './styles.ve.css';

export interface AllTeamMembersHeaderProps extends Pick<AllTeamMembersDrawerProps, 'agencyName' | 'agencyProfileUrl'> {
    contactButtonProps: AgencyContactButtonProps['contactButtonProps'];
    agencyContactButtonProps: Omit<AgencyContactButtonProps, 'contactButtonProps'>;
}

export const AllTeamMembersHeader: FC<AllTeamMembersHeaderProps> = ({
    agencyName,
    agencyProfileUrl,
    contactButtonProps,
    agencyContactButtonProps,
}) => (
    <Container display="flex" direction="column" width="100%">
        <Container display="flex" justifyContent="flexEnd" width="100%">
            <DrawerCloseButton />
        </Container>

        <Stack justifyContent="spaceBetween" alignItems="center">
            <Container display="flex" gap="4">
                <img alt="Agency avatar" src={agencyProfileUrl} className={rectangleAvatar} />
                <AgencyNameLocation agencyName={agencyName} />
            </Container>
            <Container hidden={{ default: true, sm: false }}>
                <AgencyContactButton {...agencyContactButtonProps} contactButtonProps={contactButtonProps} />
            </Container>
        </Stack>
    </Container>
);
