import { getWindow } from '@fiverr-private/seller_pages_toolbox';

export enum TabName {
    About = 'About',
    Work = 'Work',
    Services = 'Services',
    Reviews = 'Reviews',
    Testimonials = 'Testimonials',
}

const HEADER_OFFSET = 20;

export const scrollToTab = (tabId: TabName, offset = 0) => {
    const targetId = document.getElementById(tabId);

    if (!targetId) {
        return;
    }

    const { top } = targetId.getBoundingClientRect();

    getWindow()?.scrollBy({ top: top + offset, behavior: 'smooth' });
};

export const getHeaderHeight = (headerRef: React.MutableRefObject<any>) => {
    const height = headerRef.current ? headerRef.current.children[0]?.getBoundingClientRect().height : 0;

    return height ? height + HEADER_OFFSET : 0;
};
