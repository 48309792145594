import React from 'react';
import { Button } from '@fiverr-private/fit';
import { PlusIcon } from '@fiverr-private/icons';

type ButtonProps = React.ComponentProps<typeof Button>;

interface PlusButtonProps extends React.PropsWithChildren<Omit<ButtonProps, 'ref'>> {}

export const PlusButton: React.FC<PlusButtonProps> = ({ children, ...props }) => (
    <Button color="black" fill="ghost" {...props}>
        <div className="flex flex-items-center">
            <PlusIcon size={12} color="currentColor" />
            <span className="p-l-8 flex items-center">{children}</span>
        </div>
    </Button>
);
