import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { CalendarEventIcon } from '@fiverr-private/icons';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { theme } from '@fiverr-private/theme';

interface EstablishedYearProps {
    year: number;
}

export const EstablishedYear = ({ year }: EstablishedYearProps) => (
    <Stack alignItems="center" gap="2">
        <Icon color={theme.colors.grey_1200} size={18}>
            <CalendarEventIcon />
        </Icon>
        <Text size="b_lg" fontWeight="semibold" color="bodyPrimary">
            <I18n k="agency_page_perseus.established_year" params={{ year }} />
        </Text>
    </Stack>
);
