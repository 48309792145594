import React, { useRef, useState } from 'react';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { useEnteredView, useIsClient } from '@fiverr-private/hooks';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { logger } from '@fiverr-private/obs';
import { Carousel } from '@fiverr-private/orca';
import { atoms } from '@fiverr-private/theme';
import { Link, Typography } from '@fiverr-private/typography';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { bigQuery, EVENT_TYPE } from '../../monitoring/bigQuery';
import { useAgency } from '../../hooks/useAgency';
import { mixpanel } from '../../monitoring/mixpanel';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { AllTeamMembersDrawer } from './AllTeamMembersDrawer';
import { TeamMemberTile } from './TeamMemberTile';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the AgencyTeam Component'), { cause: error });
};

const useSlidesToShow = () => {
    const { isMD, isXL, isSM } = useBreakpoints();
    if (isXL) {
        return 4;
    } else if (isMD) {
        return 3;
    } else if (isSM) {
        return 2;
    }
    return 1;
};

export const AgencyTeam = () => {
    const observableRef = useRef<HTMLDivElement>(null);
    const agency = useAgency();
    const { displayName, profileImageUrl } = useAgencySellerData(selectSellerSlice);
    const isClient = useIsClient();
    const slidesToShow = useSlidesToShow();
    const [isAllMembersDrawerOpen, setIsAllMembersDrawerOpen] = useState(false);

    const agencyMembers = agency.members || [];

    useEnteredView(observableRef, {
        threshold: 1,
        onEntered: () => {
            bigQuery.impressionEvent(EVENT_TYPE.AGENCY_OUR_TEAM_IS_SHOWN, {
                agency: {
                    see_all_on_employee_exist: slidesToShow < agencyMembers.length,
                    navigation_button_exist: slidesToShow < agencyMembers.length,
                },
            });
            mixpanel.event('Team members - viewed');
        },
    });

    const showAllMembersDrawer = () => {
        setIsAllMembersDrawerOpen(true);
        mixpanel.event('Team members - See all clicked');

        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_SEE_ALL_IN_OUR_TEAM, {
            page: {
                element: {
                    text: translate('agency_page_perseus.agency_team.see_all', {
                        params: { total: agencyMembers.length },
                    }),
                },
            },
        });
    };

    const onNavigationClick = () => bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_NAVIGATION_IN_OUR_TEAM);

    const hideAllMembersDrawer = () => setIsAllMembersDrawerOpen(false);

    return (
        <ErrorBoundary onError={onRenderError}>
            <Container ref={observableRef}>
                <Container justifyContent="spaceBetween" display="flex">
                    <Typography size="h_md" fontWeight="bold" color="grey_1200">
                        <I18n k="agency_page_perseus.agency_team.title" />
                    </Typography>
                    {slidesToShow < agencyMembers.length && (
                        <Link onClick={showAllMembersDrawer} cursor="pointer">
                            <I18n
                                k="agency_page_perseus.agency_team.see_all"
                                params={{ total: agencyMembers.length }}
                            />
                        </Link>
                    )}
                </Container>
                {isClient && (
                    <Carousel
                        onArrowClick={onNavigationClick}
                        slidesToShow={slidesToShow}
                        slides={agencyMembers}
                        Component={TeamMemberTile}
                        componentProps={{ navigationButtonExist: slidesToShow < agencyMembers.length }}
                        carouselClassName={atoms({
                            marginTop: '6',
                            marginBottom: '6',
                        })}
                        arrowsClassName={atoms({
                            boxShadow: 'aa',
                        })}
                    />
                )}
                <AllTeamMembersDrawer
                    isOpen={isAllMembersDrawerOpen}
                    members={agencyMembers}
                    agencyProfileUrl={profileImageUrl as string}
                    agencyName={displayName as string}
                    onClose={hideAllMembersDrawer}
                />
            </Container>
        </ErrorBoundary>
    );
};

const selectSellerSlice = (seller: AgencySeller) => ({
    displayName: seller.user.profile.displayName,
    profileImageUrl: seller.user.profileImageUrl,
});
