import React, { useCallback, useMemo, useRef, useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { Container, Section, Stack } from '@fiverr-private/layout_components';
import { Tab } from '@fiverr-private/navigation';
import { Slide } from '@fiverr-private/transition';
import { ButtonsGroup, MonitoringSources, UserInfoContainer } from '@fiverr-private/seller_pages_toolbox';
import { atoms } from '@fiverr-private/theme';
import { useAgencyReviews } from '../../hooks/useAgencyReviews';
import { useIsStickyLayoutVisible } from '../../hooks/useIsStickyLayoutVisible';
import { mixpanel } from '../../monitoring/mixpanel';
import { bigQuery, EVENT_TYPE } from '../../monitoring/bigQuery';
import { AgencyAvatar } from '../AgencyAvatar';
import { AgencyContactButton } from '../AgencyContactButton';
import {
    CONTACT_BUTTON_POSITIONS,
    contactButtonBiEnrichment,
} from '../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { useShowContactSellerModalUpdater } from '../../hooks/useShowContactSellerModal';
import { usePortfolio } from '../../hooks/usePortfolio/usePortfolio';
import { ProfileBadgeRatings } from '../ProfileBadgeRatings';
import { RespondTimeInfo } from '../RespondTimeInfo';
import { useIsSelfView } from '../../hooks/useIsSelfView';
import { useIsSellerOnline } from '../../hooks/useIsSellerOnline';

import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { containerClass } from './styles.ve.css';
import { getHeaderHeight, scrollToTab, TabName } from './utils';
import { useScrollObserve } from './useScrollObserve';
import { SaveAgencyButton } from './SaveAgencyButton';

export interface StickyHeaderProps {
    observeHideRef?: React.RefObject<HTMLElement>;
}

export const StickyHeader = ({ observeHideRef }: StickyHeaderProps) => {
    const { userId } = getContext();
    const isSelfView = useIsSelfView();
    const { isPro, profileImageUrl, testimonials } = useAgencySellerData(selectSellerSlice);
    const setShowContactSellerModal = useShowContactSellerModalUpdater();
    const { showPortfolio } = usePortfolio();
    const reviews = useAgencyReviews();
    const headerRef = useRef(null);
    const isVisible = useIsStickyLayoutVisible(observeHideRef);
    const [activeTab, setActiveTab] = useState(TabName.About);
    const isSellerOnline = useIsSellerOnline();

    const hasReviews = !!reviews?.buying_reviews?.reviews?.length;
    const canShowSaveButton = userId && !isSelfView;

    const tabNames = useMemo(() => Object.values(TabName), []);

    const handleTabIntersect = useCallback(
        (currentTab) => {
            if (currentTab && currentTab !== activeTab) {
                setActiveTab(currentTab);
            }
        },
        [activeTab]
    );

    /* Track scroll position to reflect the currently active tab in the header tab's list */
    useScrollObserve({
        isHeaderActive: isVisible,
        headerHeight: getHeaderHeight(headerRef),
        headerTabIds: tabNames,
        onHeaderTabIntersect: handleTabIntersect,
    });

    const handleTabSwitch = useCallback(
        (tabName: TabName) => {
            const offset = getHeaderHeight(headerRef);

            scrollToTab(tabName, -offset);
        },
        [headerRef]
    );

    const onRatingsClick = useCallback(() => {
        handleTabSwitch(TabName.Reviews);
    }, [handleTabSwitch]);

    const handleContactOpen = () => {
        setShowContactSellerModal(true);
        mixpanel.event('Contact us clicked');
    };

    const contactButtonProps = {
        biEnrichment: contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.STICKY }),
        title: translate('agency_page_perseus.contact_us'),
        onContactOpen: handleContactOpen,
        source: MonitoringSources.stickyHeader,
    };

    const agencyContactButtonProps = {
        biEnrichment: contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.STICKY_HEADER }),
        source: CONTACT_BUTTON_POSITIONS.STICKY_HEADER,
    };

    const handleTabClick = (tab: TabName, mixpanelEventName: string) => {
        handleTabSwitch(tab);
        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_AGENCY_TABS, { page: { element: { text: tab } } });
        mixpanel.event(`Tabs clicked - ${mixpanelEventName}`);
    };

    return (
        <Slide in={isVisible} unmountOnExit direction="top" easing="easeInOut">
            <Container
                ref={headerRef}
                position="fixed"
                width="100%"
                top={0}
                backgroundColor="white"
                className={containerClass}
            >
                <Section paddingTop="4" paddingBottom="0">
                    <Stack justifyContent="spaceBetween" marginBottom="2">
                        <Stack gap="6">
                            <Container hidden={{ default: true, md: false }}>
                                <AgencyAvatar
                                    isMinimized
                                    isPro={Boolean(isPro)}
                                    avatarUrl={profileImageUrl as string}
                                />
                            </Container>
                            <Stack direction="column" justifyContent="spaceAround" minHeight="56px" gap="1">
                                <ProfileBadgeRatings
                                    displayNameOnly
                                    usernameClassName={atoms({ fontSize: 'b_lg', lineHeight: 'b_lg' })}
                                    onRatingsClick={onRatingsClick}
                                    source={MonitoringSources.stickyHeader}
                                />
                                <Container hidden={{ default: true, md: false }}>
                                    <UserInfoContainer isOnline={isSellerOnline} />
                                </Container>
                            </Stack>
                        </Stack>
                        <Stack direction="column" gap="3" hidden={{ default: true, md: false }}>
                            <ButtonsGroup className={atoms({ justifyContent: 'flexEnd' })}>
                                {canShowSaveButton && <SaveAgencyButton />}
                                <AgencyContactButton
                                    {...agencyContactButtonProps}
                                    contactButtonProps={contactButtonProps}
                                />
                            </ButtonsGroup>
                            <RespondTimeInfo />
                        </Stack>
                    </Stack>
                    <Stack wrap="nowrap" overflowX="scroll" overflowY="hidden" gap="0">
                        <Tab
                            selected={activeTab === TabName.About}
                            onClick={() => handleTabClick(TabName.About, 'About us')}
                            borderBottomWidth={0}
                        >
                            {translate('agency_page_perseus.sticky_header.about_tab')}
                        </Tab>
                        {showPortfolio && (
                            <Tab
                                selected={activeTab === TabName.Work}
                                onClick={() => handleTabClick(TabName.Work, 'Our work')}
                                borderBottomWidth={0}
                            >
                                {translate('agency_page_perseus.sticky_header.work_tab')}
                            </Tab>
                        )}
                        <Tab
                            selected={activeTab === TabName.Services}
                            onClick={() => handleTabClick(TabName.Services, 'Services')}
                            borderBottomWidth={0}
                        >
                            {translate('agency_page_perseus.sticky_header.services_tab')}
                        </Tab>
                        {hasReviews && (
                            <Tab
                                selected={activeTab === TabName.Reviews}
                                onClick={() => handleTabClick(TabName.Reviews, 'Reviews')}
                                borderBottomWidth={0}
                            >
                                {translate('agency_page_perseus.sticky_header.reviews_tab')}
                            </Tab>
                        )}
                        {!!testimonials?.length && (
                            <Tab
                                selected={activeTab === TabName.Testimonials}
                                onClick={() => handleTabClick(TabName.Testimonials, 'Testimonials')}
                                borderBottomWidth={0}
                            >
                                {translate('agency_page_perseus.sticky_header.testimonials_tab')}
                            </Tab>
                        )}
                    </Stack>
                </Section>
                <Container height="1px" position="relative" backgroundColor="grey_400" boxShadow="z2" width="100%" />
            </Container>
        </Slide>
    );
};

StickyHeader.TabName = TabName;

const selectSellerSlice = (seller: AgencySeller) => ({
    isPro: seller.isPro,
    profileImageUrl: seller.user.profileImageUrl,
    testimonials: seller.testimonials,
});
