import { useQuery } from 'react-query';
import { getIsUserOnline } from '@fiverr-private/seller_pages_toolbox';
import { useAgencySellerData } from '../useAgencySellerData';
import { AgencySeller } from '../../types';

const BASE_KEY = 'is-user-online';
const MIN = 1000 * 60;

const useIsUserOnlineQuery = (username: string) => {
    const data = useQuery([BASE_KEY, username], () => getIsUserOnline(username), {
        cacheTime: 0,
        refetchInterval: 3 * MIN,
    });
    return data;
};

export const useIsSellerOnline = () => {
    const { username } = useAgencySellerData(selectSellerSlice);

    const { data: { isOnline = false } = {} } = useIsUserOnlineQuery(username);
    return isOnline;
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        username: seller.user.name,
    };
}
