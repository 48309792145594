import { getContext } from '@fiverr-private/fiverr_context';
import { parseActiveArrayFilters } from '../../../../server/utils/parseActiveArrayFilters';
import { useAgencySellerData } from '../../../hooks/useAgencySellerData';
import { AgencySeller } from '../../../types';

export const useMostRelevantGigInfo = () => {
    const {
        queryParameters: { gigs: gigsQueryParam = '', referrer_gig_slug },
    } = getContext();

    const { gigs } = useAgencySellerData(selectSellerSlice);

    const mostRelevantGigInitializers =
        !!gigsQueryParam && typeof gigsQueryParam === 'string' ? parseActiveArrayFilters(gigsQueryParam)[0] : {};

    const mostRelevantGigId = mostRelevantGigInitializers?.id ? mostRelevantGigInitializers.id : undefined;

    const mostRelevantGigSlug = mostRelevantGigInitializers?.slug || referrer_gig_slug;

    let mostRelevantGigById: Gig | undefined;
    let mostRelevantGigBySlug: Gig | undefined;

    if (typeof mostRelevantGigId === 'string') {
        mostRelevantGigById = gigs?.find(({ id }) => id === mostRelevantGigId);
    }

    if (typeof mostRelevantGigSlug === 'string') {
        mostRelevantGigBySlug = gigs?.find(({ slug }) => slug === mostRelevantGigSlug);
    }

    const bestCandidate = mostRelevantGigById ?? mostRelevantGigBySlug ?? gigs?.[0];

    if (bestCandidate) {
        return {
            gigId: bestCandidate?.id,
            subCategoryId: bestCandidate.subCategoryId,
            categoryId: bestCandidate.categoryId,
        };
    }

    return {};
};

interface Gig {
    id: string;
    slug: string;
    subCategoryId: number;
    categoryId: number;
}

const selectSellerSlice = ({ gigs: { nodes } }: AgencySeller) => ({
    gigs: nodes?.map(
        ({ id, slug, category: { subCategoryId, categoryId } }) =>
            ({
                id,
                slug,
                subCategoryId,
                categoryId,
            } as Gig)
    ),
});
