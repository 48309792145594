export const CONTACT_BUTTON_POSITIONS = {
    HEADER: 'header',
    OUR_TEAM_SECTION: 'our team section',
    STICKY: 'sticky',
    ALL_TEAM_MODAL: 'all team modal',
    STICKY_HEADER: 'sticky header',
    STICKY_FOOTER: 'sticky footer',
    SERVICES_SECTION: 'services section',
    SERVICE_MODAL: 'service modal',
} as const;

export type ContactButtonPosition = ValueOf<typeof CONTACT_BUTTON_POSITIONS>;

export const contactButtonBiEnrichment = ({ position }: { position: ContactButtonPosition }) => ({
    domain: {
        name: 'agencies',
    },
    page: {
        component: {
            position,
        },
        name: 'agency page',
    },
    event_source: 'agency_page',
});

export type ContactBIEnrichment = ReturnType<typeof contactButtonBiEnrichment>;
